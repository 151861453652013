export const GET_LATEST_ARTICLES = "GET_LATEST_ARTICLES";
export const GET_LATEST_ARTICLES_PENDING = "GET_LATEST_ARTICLES_PENDING";
export const GET_LATEST_ARTICLES_REJECTED = "GET_LATEST_ARTICLES_REJECTED";
export const GET_LATEST_ARTICLES_FULFILLED = "GET_LATEST_ARTICLES_FULFILLED";
export const EMPTY_LATEST_ARTICLES = "EMPTY_LATEST_ARTICLES";

export const SHOW_UNKNOWN_ARTICLE_POPUP = "SHOW_UNKNOWN_ARTICLE_POPUP";
export const HIDE_UNKNOWN_ARTICLE_POPUP = "HIDE_UNKNOWN_ARTICLE_POPUP";

export const SHOW_NEW_ARTICLE_POPUP = "SHOW_NEW_ARTICLE_POPUP";
export const HIDE_NEW_ARTICLE_POPUP = "HIDE_NEW_ARTICLE_POPUP";
export const EXIT_NEW_ARTICLE_POPUP = "EXIT_NEW_ARTICLE_POPUP";

export const SHOW_DELETE_ARTICLE_POPUP = "SHOW_DELETE_ARTICLE_POPUP";
export const HIDE_DELETE_ARTICLE_POPUP = "HIDE_DELETE_ARTICLE_POPUP";

export const SHOW_DELETE_ARTICLE_FROM_COMMAND_POPUP =
  "SHOW_DELETE_ARTICLE_FROM_COMMAND_POPUP";
export const HIDE_DELETE_ARTICLE_FROM_COMMAND_POPUP =
  "HIDE_DELETE_ARTICLE_FROM_COMMAND_POPUP";

export const HANDLE_INPUT_EAN = "HANDLE_INPUT_EAN";
export const FETCH_NEW_ARTICLE_INFOS_IN_REDUCER =
  "FETCH_NEW_ARTICLE_INFOS_IN_REDUCER";
export const EMPTY_NEW_ARTICLE_INFOS_IN_REDUCER =
  "EMPTY_NEW_ARTICLE_INFOS_IN_REDUCER";

export const DELETE_ARTICLE_FROM_STORE = "DELETE_ARTICLE_FROM_STORE";
export const DELETE_ARTICLE_FROM_STORE_PENDING =
  "DELETE_ARTICLE_FROM_STORE_PENDING";
export const DELETE_ARTICLE_FROM_STORE_REJECTED =
  "DELETE_ARTICLE_FROM_STORE_REJECTED";
export const DELETE_ARTICLE_FROM_STORE_FULFILLED =
  "DELETE_ARTICLE_FROM_STORE_FULFILLED";

export const DELETE_ARTICLE_FROM_COMMAND = "DELETE_ARTICLE_FROM_COMMAND";
export const DELETE_ARTICLE_FROM_COMMAND_PENDING =
  "DELETE_ARTICLE_FROM_COMMAND_PENDING";
export const DELETE_ARTICLE_FROM_COMMAND_REJECTED =
  "DELETE_ARTICLE_FROM_COMMAND_REJECTED";
export const DELETE_ARTICLE_FROM_COMMAND_FULFILLED =
  "DELETE_ARTICLE_FROM_COMMAND_FULFILLED";

export const ADD_NEW_ARTICLE_TO_STORE = "ADD_NEW_ARTICLE_TO_STORE";
export const ADD_NEW_ARTICLE_TO_STORE_PENDING =
  "ADD_NEW_ARTICLE_TO_STORE_PENDING";
export const ADD_NEW_ARTICLE_TO_STORE_REJECTED =
  "ADD_NEW_ARTICLE_TO_STORE_REJECTED";
export const ADD_NEW_ARTICLE_TO_STORE_FULFILLED =
  "ADD_NEW_ARTICLE_TO_STORE_FULFILLED";

export const SEARCH_ARTICLES_QUERY = "SEARCH_ARTICLES_QUERY";
export const SEARCH_ARTICLES_QUERY_PENDING = "SEARCH_ARTICLES_QUERY_PENDING";
export const SEARCH_ARTICLES_QUERY_REJECTED = "SEARCH_ARTICLES_QUERY_REJECTED";
export const SEARCH_ARTICLES_QUERY_FULFILLED =
  "SEARCH_ARTICLES_QUERY_FULFILLED";
export const EMPTY_SEARCH_ARTICLES_QUERY = "EMPTY_SEARCH_ARTICLES_QUERY";
