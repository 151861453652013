import React from "react";
import ArticlesList from "../ArticlesList/index";
import { connect } from "react-redux";
import "./index.scss";

import { emptySearchArticlesQuery } from "../../actions/articles.actions";

type StoreSearchOverviewProps = {
  // Redux State
  searchArticles?: any;

  // Redux Action
  emptySearchArticlesQuery?: any;
};

class StoreSearchOverview extends React.Component<StoreSearchOverviewProps> {
  componentWillUnmount() {
    const { emptySearchArticlesQuery } = this.props;
    emptySearchArticlesQuery();
  }

  render() {
    const { searchArticles } = this.props;

    return (
      <div className="StoreAddOverview">
        {searchArticles.length === 0 ? null : (
          <ArticlesList dataArticlesList={searchArticles} isInfiniteScroll />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchArticles: state.articles.searchArticles,
});

const mapsDispatchToProps = (dispatch) => ({
  emptySearchArticlesQuery: () => dispatch(emptySearchArticlesQuery()),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(StoreSearchOverview);
