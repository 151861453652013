import { put } from "redux-saga/effects";
import { getAllCategories } from "../actions/categories.actions";
import { resetActiveStoreTab } from "../actions/menus.actions";
import { emptyStoreInputFieldValue } from "../actions/store.actions";
import {
  hideUnknownArticlePopup,
  hideDeleteArticlePopup,
  hideNewArticlePopup,
  emptyNewArticleInfosInReducer,
} from "../actions/articles.actions";
import { handleCategoriesReset } from "../actions/categories.actions";

export function* storeInitLogic() {
  yield put(resetActiveStoreTab());
  yield put(emptyStoreInputFieldValue());

  yield put(handleCategoriesReset());

  yield put(hideDeleteArticlePopup());

  yield put(hideNewArticlePopup());
  yield put(emptyNewArticleInfosInReducer());

  yield put(hideUnknownArticlePopup());

  yield put(getAllCategories());
}
