import React from "react";
import PageHeader from "../../components/PageHeader/index";
import CommandsLabels from "../../components/CommandsLabels/index";
import CommandLine from "../../components/CommandLine/index";
import CommandOverview from "../../components/CommandOverview/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "./index.scss";

import { emptyFocusedCommand } from "../../actions/commands.actions";

type CommandsHistoryDetailsProps = RouteComponentProps & {
  // Redux State
  focusedCommandInfos?: any;

  // Redux actions
  emptyFocusedCommand?: any;
};

class CommandsHistoryDetails extends React.Component<
  CommandsHistoryDetailsProps
> {
  componentWillUnmount() {
    const { emptyFocusedCommand } = this.props;
    emptyFocusedCommand();
  }

  render() {
    const { focusedCommandInfos } = this.props;

    return (
      <div className="CommandsHistoryDetails center">
        <PageHeader title="Details de la commande" />
        <CommandsLabels />
        {Object.keys(focusedCommandInfos).length === 0 ? (
          <Skeleton className="commandLineSkeleton" />
        ) : (
          <CommandLine
            commandData={focusedCommandInfos}
            commandClassName="CommandLine focusedCommand"
          />
        )}
        <CommandOverview />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  focusedCommandInfos: state.commands.focusedCommand.focusedCommandInfos,
});

const mapDispatchToProps = (dispatch) => ({
  emptyFocusedCommand: () => dispatch(emptyFocusedCommand()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommandsHistoryDetails)
);
