import React from "react";
import "./index.scss";

const CommandsLabels = () => {
  return (
    <div className="CommandsLabels">
      <ul>
        <li>
          <p>Numéro de commande</p>
        </li>
        <li>
          <p>Nom du client</p>
        </li>
        <li>
          <p>Heure & Date de commande</p>
        </li>
        <li>
          <p>Nombre d'articles</p>
        </li>
        <li>
          <p>État de la commande</p>
        </li>
      </ul>
    </div>
  );
};

export default CommandsLabels;
