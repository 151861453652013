import * as commandsConst from "../const/commands.const";
import {
  getLatestNewCommandsCountFromDB,
  getLatestNewCommandsFromDB,
  getLatestPendingCommandsFromDB,
  getLatestCanceledCommandsFromDB,
  getLatestHistoryCommandsFromDB,
  getFocusedCommandFromDB,
  patchFocusedCommandToPendingFromDB,
  patchFocusedCommandToCanceledFromDB,
  patchFocusedCommandToHistoryFromDB,
  searchCommandsQueryFromDB,
} from "../api/commands.api";

export const toggleConfirmButton = () => ({
  type: commandsConst.TOGGLE_CONFIRM_BUTTON,
});
export const resetConfirmButton = () => ({
  type: commandsConst.RESET_CONFIRM_BUTTON,
});

export const getLatestNewCommandsCount = () => ({
  type: commandsConst.GET_LATEST_NEW_COMMANDS_COUNT,
  payload: getLatestNewCommandsCountFromDB(),
});
export const getLatestNewCommands = (latestNewCommandsPage) => ({
  type: commandsConst.GET_LATEST_NEW_COMMANDS,
  payload: getLatestNewCommandsFromDB(latestNewCommandsPage),
});
export const emptyLatestNewCommands = () => ({
  type: commandsConst.EMPTY_LATEST_NEW_COMMANDS,
});

export const getLatestPendingCommands = (latestPendingCommandsPage) => ({
  type: commandsConst.GET_LATEST_PENDING_COMMANDS,
  payload: getLatestPendingCommandsFromDB(latestPendingCommandsPage),
});
export const emptyLatestPendingCommands = () => ({
  type: commandsConst.EMPTY_LATEST_PENDING_COMMANDS,
});

export const getLatestCanceledCommands = (latestCanceledCommandsPage) => ({
  type: commandsConst.GET_LATEST_CANCELED_COMMANDS,
  payload: getLatestCanceledCommandsFromDB(latestCanceledCommandsPage),
});
export const emptyLatestCanceledCommands = () => ({
  type: commandsConst.EMPTY_LATEST_CANCELED_COMMANDS,
});

export const getLatestHistoryCommands = (latestHistoryCommandsPage) => ({
  type: commandsConst.GET_LATEST_HISTORY_COMMANDS,
  payload: getLatestHistoryCommandsFromDB(latestHistoryCommandsPage),
});
export const emptyLatestHistoryCommands = () => ({
  type: commandsConst.EMPTY_LATEST_HISTORY_COMMANDS,
});

export const getFocusedCommand = (id_order) => ({
  type: commandsConst.GET_FOCUSED_COMMAND,
  payload: getFocusedCommandFromDB(id_order),
});
export const emptyFocusedCommand = () => ({
  type: commandsConst.EMPTY_FOCUSED_COMMAND,
});

export const patchFocusedCommandToPending = (id_order) => ({
  type: commandsConst.PATCH_FOCUSED_COMMAND_TO_PENDING,
  payload: patchFocusedCommandToPendingFromDB(id_order),
});
export const patchFocusedCommandToCanceled = (id_order) => ({
  type: commandsConst.PATCH_FOCUSED_COMMAND_TO_CANCELED,
  payload: patchFocusedCommandToCanceledFromDB(id_order),
});
export const patchFocusedCommandToHistory = (id_order) => ({
  type: commandsConst.PATCH_FOCUSED_COMMAND_TO_HISTORY,
  payload: patchFocusedCommandToHistoryFromDB(id_order),
});

export const searchCommandsQuery = (input_value) => ({
  type: commandsConst.SEARCH_COMMANDS_QUERY,
  payload: searchCommandsQueryFromDB(input_value),
});

export const emptySearchCommandsQuery = () => ({
  type: commandsConst.EMPTY_SEARCH_COMMANDS_QUERY,
});

export const commandsLoading = () => ({
  type: commandsConst.COMMANDS_LOADING,
});

export const commandsNull = () => ({
  type: commandsConst.COMMANDS_NULL,
});
