import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import "./index.scss";

type TotalGestionProps = RouteComponentProps & {
  // Props
  urlToCheck?: string | string[];

  // Redux State
  focusedCommandTotalHT?: any;
  focusedCommandTVA?: any;
  focusedCommandTotalTTC?: any;
};

class TotalGestion extends React.Component<TotalGestionProps> {
  // Component render gestion checking URL
  renderTotalGestion(urlToCheck: string | string[]) {
    const {
      focusedCommandTotalHT,
      focusedCommandTVA,
      focusedCommandTotalTTC,
    } = this.props;
    if (urlToCheck.includes("commands_canceled/details")) {
      return (
        <React.Fragment>
          <div className="cancelInfos">
            <strong>Commande annulée le 18 mai 2020 à 14h30</strong>
            <p>
              Les articles de cette commande seront automatiquement remis à la
              vente sous 24h.
            </p>
          </div>
          <div className="totalCanceledInfos">
            <ul>
              <li>
                <p>Total HT</p>
                <p>{focusedCommandTotalHT.toFixed(2)} €</p>
              </li>
              <li>
                <p>TVA</p>
                <p>{focusedCommandTVA.toFixed(2)} €</p>
              </li>
              <li>
                <strong>Total TTC</strong>
                <strong>{focusedCommandTotalTTC.toFixed(2)} €</strong>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="totalInfos">
          <ul>
            <li>
              <p>Total HT</p>
              <p>{focusedCommandTotalHT.toFixed(2)} €</p>
            </li>
            <li>
              <p>TVA</p>
              <p>{focusedCommandTVA.toFixed(2)} €</p>
            </li>
            <li>
              <strong>Total TTC</strong>
              <strong>{focusedCommandTotalTTC.toFixed(2)} €</strong>
            </li>
          </ul>
        </div>
      );
    }
  }

  // Component Render
  render() {
    const urlToCheck = this.props.location.pathname;

    return (
      <React.Fragment>
        <div className="hr"></div>
        <div className="TotalGestion">
          {this.renderTotalGestion(urlToCheck)}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  focusedCommandTotalHT: state.commands.focusedCommand.focusedCommandTotalHT,
  focusedCommandTVA: state.commands.focusedCommand.focusedCommandTVA,
  focusedCommandTotalTTC: state.commands.focusedCommand.focusedCommandTotalTTC,
});

export default withRouter(connect(mapStateToProps)(TotalGestion));
