import React from "react";
import ScanSet from "../ScanSet/index";
import SearchSet from "../SearchSet/index";
import SearchCommandsSet from "../SearchCommandsSet/index";
import { connect } from "react-redux";
import "./index.scss";

import { toggleActiveStoreTab } from "../../actions/menus.actions";

type ScanSearchTabProps = {
  // Redux State
  activeStoreTab?: number;
  isNewArticlePopupShown?: boolean;

  // Redux Actions
  toggleActiveStoreTab?: any;
};

class ScanSearchTab extends React.Component<ScanSearchTabProps> {
  // Component render
  render() {
    const {
      activeStoreTab,
      toggleActiveStoreTab,
      isNewArticlePopupShown,
    } = this.props;

    return (
      <div
        className={
          isNewArticlePopupShown ? "ScanSearchTab disabledTab" : "ScanSearchTab"
        }
      >
        <div className="tabSelector">
          <div
            className={
              activeStoreTab === 1
                ? "buttonContainer selectedTab"
                : "buttonContainer"
            }
            onClick={() => toggleActiveStoreTab(1)}
          >
            <img src="/img/add_icon.png" alt="Icone Ajout" />
            <button>Ajouter un article</button>
          </div>

          <div
            className={
              activeStoreTab === 2
                ? "buttonContainer selectedTab"
                : "buttonContainer"
            }
            onClick={() => toggleActiveStoreTab(2)}
          >
            <img src="/img/search_icon.png" alt="Icone Recherche" />
            <button>Rechercher un article</button>
          </div>

          <div
            className={
              activeStoreTab === 3
                ? "buttonContainer selectedTab"
                : "buttonContainer"
            }
            onClick={() => toggleActiveStoreTab(3)}
          >
            <img src="/img/icon_search_command.png" alt="Icone Recherche" />
            <button>Rechercher une commande</button>
          </div>
        </div>
        <div
          className={
            isNewArticlePopupShown
              ? "scanSearchField disabledField"
              : "scanSearchField"
          }
        >
          {activeStoreTab === 1 ? (
            <ScanSet />
          ) : activeStoreTab === 2 ? (
            <SearchSet />
          ) : activeStoreTab === 3 ? (
            <SearchCommandsSet />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStoreTab: state.menus.activeStoreTab,
  isNewArticlePopupShown: state.articles.isNewArticlePopupShown,
});

const mapDispatchToProps = (dispatch) => ({
  toggleActiveStoreTab: (id_tab) => dispatch(toggleActiveStoreTab(id_tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanSearchTab);
