import * as commandsConst from "../const/commands.const";
import * as userConst from "../const/user.const";

const initState = {
  isConfirmButtonEnabled: false,
  hasMoreCommands: false,
  latestNewCommandsCount: 0,
  latestNewCommands: [],
  latestNewCommandsPage: 1,
  latestPendingCommands: [],
  latestPendingCommandsPage: 1,
  latestCanceledCommands: [],
  latestCanceledCommandsPage: 1,
  latestHistoryCommands: [],
  latestHistoryCommandsPage: 1,
  isFocusedCommandsArticlesListLoading: false,
  focusedCommand: {
    focusedCommandInfos: {},
    focusedCommandArticlesList: [],
    focusedCommandTotalTTC: 0,
    focusedCommandTVA: 0,
    focusedCommandTotalHT: 0,
  },
  searchCommands: [],
  commandsAreLoading: null,
};

const commands = (state = initState, action) => {
  switch (action.type) {
    case commandsConst.TOGGLE_CONFIRM_BUTTON:
      return {
        ...state,
        isConfirmButtonEnabled: !state.isConfirmButtonEnabled,
      };

    case commandsConst.RESET_CONFIRM_BUTTON:
      return { ...state, isConfirmButtonEnabled: false };

    case commandsConst.GET_LATEST_NEW_COMMANDS_COUNT_FULFILLED:
      return {
        ...state,
        latestNewCommandsCount: action.payload.data.new_orders_count,
      };

    case commandsConst.GET_LATEST_NEW_COMMANDS_PENDING:
      return { ...state, hasMoreCommands: true };

    case commandsConst.GET_LATEST_NEW_COMMANDS_FULFILLED:
      if (action.payload.data.length === 0) {
        return { ...state, hasMoreCommands: false, commandsAreLoading: false };
      } else {
        return {
          ...state,
          hasMoreCommands: true,
          latestNewCommands: state.latestNewCommands.concat(
            action.payload.data
          ),
          latestNewCommandsPage: state.latestNewCommandsPage + 1,
          commandsAreLoading: false,
        };
      }

    case commandsConst.EMPTY_LATEST_NEW_COMMANDS:
      return { ...state, latestNewCommands: [], latestNewCommandsPage: 1 };

    case commandsConst.GET_LATEST_PENDING_COMMANDS_PENDING:
      return { ...state, hasMoreCommands: true };

    case commandsConst.GET_LATEST_PENDING_COMMANDS_FULFILLED:
      if (action.payload.data.length === 0) {
        return { ...state, hasMoreCommands: false, commandsAreLoading: false };
      } else {
        return {
          ...state,
          hasMoreCommands: true,
          latestPendingCommands: state.latestPendingCommands.concat(
            action.payload.data
          ),
          latestPendingCommandsPage: state.latestPendingCommandsPage + 1,
          commandsAreLoading: false,
        };
      }

    case commandsConst.EMPTY_LATEST_PENDING_COMMANDS:
      return {
        ...state,
        latestPendingCommands: [],
        latestPendingCommandsPage: 1,
      };

    case commandsConst.GET_LATEST_CANCELED_COMMANDS_PENDING:
      return { ...state, hasMoreCommands: true };

    case commandsConst.GET_LATEST_CANCELED_COMMANDS_FULFILLED:
      if (action.payload.data.length === 0) {
        return { ...state, hasMoreCommands: false, commandsAreLoading: false };
      } else {
        return {
          ...state,
          hasMoreCommands: true,
          latestCanceledCommands: state.latestCanceledCommands.concat(
            action.payload.data
          ),
          latestCanceledCommandsPage: state.latestCanceledCommandsPage + 1,
          commandsAreLoading: false,
        };
      }

    case commandsConst.EMPTY_LATEST_CANCELED_COMMANDS:
      return {
        ...state,
        latestCanceledCommands: [],
        latestCanceledCommandsPage: 1,
      };

    case commandsConst.GET_LATEST_HISTORY_COMMANDS_PENDING:
      return { ...state, hasMoreCommands: true };

    case commandsConst.GET_LATEST_HISTORY_COMMANDS_FULFILLED:
      if (action.payload.data.length === 0) {
        return { ...state, hasMoreCommands: false, commandsAreLoading: false };
      } else {
        return {
          ...state,
          hasMoreCommands: true,
          latestHistoryCommands: state.latestHistoryCommands.concat(
            action.payload.data
          ),
          latestHistoryCommandsPage: state.latestHistoryCommandsPage + 1,
          commandsAreLoading: false,
        };
      }

    case commandsConst.EMPTY_LATEST_HISTORY_COMMANDS:
      return {
        ...state,
        latestHistoryCommands: [],
        latestHistoryCommandsPage: 1,
      };

    case commandsConst.GET_FOCUSED_COMMAND_PENDING:
      return { ...state, isFocusedCommandsArticlesListLoading: true };

    case commandsConst.GET_FOCUSED_COMMAND_REJECTED:
      return { ...state, isFocusedCommandsArticlesListLoading: false };

    case commandsConst.GET_FOCUSED_COMMAND_FULFILLED:
      let temp_totalTTC = state.focusedCommand.focusedCommandTotalTTC;
      let temp_TVA = state.focusedCommand.focusedCommandTotalTTC * 0.2;
      let temp_totalHT =
        state.focusedCommand.focusedCommandTotalTTC - state.focusedCommandTVA;

      if (action.payload.data[1].length === 0) {
        temp_totalTTC = 0;
        temp_TVA = 0;
        temp_totalHT = 0;
      } else {
        temp_totalTTC = 0;
        temp_TVA = 0;
        temp_totalHT = 0;
        for (let i = 0; i < action.payload.data[1].length; i++) {
          temp_totalTTC =
            Math.round(
              (temp_totalTTC +
                action.payload.data[1][i].price +
                Number.EPSILON) *
                100
            ) / 100;
          temp_TVA =
            Math.round((temp_totalTTC * 0.2 + Number.EPSILON) * 100) / 100;
          temp_totalHT =
            Math.round((temp_totalTTC - temp_TVA + Number.EPSILON) * 100) / 100;
        }
      }

      return {
        ...state,
        isFocusedCommandsArticlesListLoading: false,
        focusedCommand: {
          focusedCommandInfos: action.payload.data[0],
          focusedCommandArticlesList: action.payload.data[1],
          focusedCommandTotalTTC: temp_totalTTC,
          focusedCommandTVA: temp_TVA,
          focusedCommandTotalHT: temp_totalHT,
        },
      };

    case commandsConst.EMPTY_FOCUSED_COMMAND:
      return {
        ...state,
        focusedCommand: {
          focusedCommandInfos: {},
          focusedCommandArticlesList: [],
          focusedCommandTotalTTC: 0,
          focusedCommandTVA: 0,
          focusedCommandTotalHT: 0,
        },
      };

    case commandsConst.SEARCH_COMMANDS_QUERY_FULFILLED:
      return { ...state, searchCommands: action.payload.data };

    case commandsConst.EMPTY_SEARCH_COMMANDS_QUERY:
      return { ...state, searchCommands: [] };

    case commandsConst.COMMANDS_LOADING:
      return { ...state, comamndsAreLoading: true };

    case commandsConst.COMMANDS_NULL:
      return { ...state, commandsAreLoading: null };

    case userConst.FETCH_LOGOUT_FULFILLED:
      return { ...initState };

    default:
      return state;
  }
};

export default commands;
