import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { login } from "../../actions/user.actions";

type LoginProps = {
  // Redux State
  logFeedback?: string;

  // Redux Actions
  login?: any;
};

type LoginState = {
  storeUsername?: any;
  storePassword?: any;
};

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props) {
    super(props);
    this.state = {
      storeUsername: "",
      storePassword: "",
    };
  }

  handleStoreId = (event) => {
    this.setState({ storeUsername: event.target.value });
  };

  handleStorePassword = (event) => {
    this.setState({ storePassword: event.target.value });
  };

  handleReset = () => {
    this.setState({ storeUsername: "", storePassword: "" });
  };

  handleSubmit = (event) => {
    const { login } = this.props;
    const { storeUsername, storePassword } = this.state;

    event.preventDefault();
    if (storeUsername === "" || storePassword === "") {
      return false;
    } else {
      login(storeUsername, storePassword);
    }
  };

  render() {
    const { logFeedback } = this.props;

    return (
      <div className="Login center">
        <div className="loginInterface">
          <div className="loginForm">
            <h3>Ravis de vous revoir</h3>
            <p>Connectez-vous avez votre identifiant magasin</p>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="idField">
                <label htmlFor="storeIdinputInput">Identifiant magasin</label>
                <input
                  type="text"
                  value={this.state.storeUsername}
                  onChange={this.handleStoreId}
                  placeholder="Saisissez votre identifiant"
                />
                {logFeedback === "Pas de compte correspondant." ? (
                  <p className="loginError">
                    <img src="/img/error_feedback.png" alt="Croix erreur" />
                    {logFeedback}
                  </p>
                ) : null}
              </div>

              <div className="passwordField">
                <label htmlFor="storePasswordInput">Mot de passe</label>
                <input
                  type="password"
                  value={this.state.storePassword}
                  onChange={this.handleStorePassword}
                  placeholder="Saisissez votre mot de passe"
                />
                {logFeedback === "Mauvais mot de passe." ? (
                  <p className="loginError">
                    <img src="/img/error_feedback.png" alt="Croix erreur" />
                    {logFeedback}
                  </p>
                ) : null}
              </div>
              <button
                type="submit"
                className={
                  this.state.storeUsername.length !== 0 &&
                  this.state.storePassword.length !== 0
                    ? "loginButtonEnabled"
                    : "loginButtonDisabled"
                }
              >
                Continuer
              </button>
            </form>
          </div>
          <div className="loginImage">
            <img src="./img/login_image.jpg" alt="Illustration Login" />
          </div>
        </div>
        <div className="contactField">
          Vous rencontrez des difficultés pour vous connecter ?{" "}
          <button>Contactez-nous</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  logFeedback: state.user.logFeedback,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password, type) =>
    dispatch(login(username, password, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
