import { combineReducers } from "redux";
import user from "./user.reducer";
import store from "./store.reducer";
import menus from "./menus.reducer";
import toasts from "./toasts.reducer";
import articles from "./articles.reducer";
import commands from "./commands.reducer";
import categories from "./categories.reducer";

export default combineReducers({
  user,
  store,
  menus,
  toasts,
  articles,
  commands,
  categories,
});
