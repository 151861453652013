import { put, call } from "redux-saga/effects";
import { history } from "../pages/Main/index";
import { closeManageMenu } from "../actions/menus.actions";
import {
  getLatestNewCommandsCount,
  emptyLatestNewCommands,
  getLatestNewCommands,
} from "../actions/commands.actions";
import { emptyStoreInputFieldValue } from "../actions/store.actions";

function forwardTo(location) {
  history.push(location);
}

export function* commandToPendingLogic() {
  yield call(forwardTo, "/commands_pending");
  yield put(getLatestNewCommandsCount());
}

export function* commandToCanceledLogic() {
  yield call(forwardTo, "/commands_canceled");
  yield put(closeManageMenu());
  yield put(getLatestNewCommandsCount());
}

export function* commandToHistoryLogic() {
  yield call(forwardTo, "/commands_history");
}

export function* toastifyNewCommandLogic() {
  yield put(getLatestNewCommandsCount());
  yield put(emptyLatestNewCommands());
  yield put(getLatestNewCommands());
}

export function* searchCommandsQueryLogic() {
  yield put(emptyStoreInputFieldValue());
}
