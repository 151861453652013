import * as menusConst from "../const/menus.const";

export const toggleActiveStoreTab = (id_tab) => ({
  type: menusConst.TOGGLE_ACTIVE_STORE_TAB,
  payload: id_tab,
});

export const resetActiveStoreTab = () => ({
  type: menusConst.RESET_ACTIVE_STORE_TAB,
});

export const openManageMenu = () => ({
  type: menusConst.OPEN_MANAGE_MENU,
  payload: document.body.classList.add("noScroll"),
});

export const closeManageMenu = () => ({
  type: menusConst.CLOSE_MANAGE_MENU,
  payload: document.body.removeAttribute("class"),
});

export const toggleCategoriesMenu = () => ({
  type: menusConst.TOGGLE_CATEGORIES_MENU,
});

export const closeCategoriesMenu = () => ({
  type: menusConst.CLOSE_CATEGORIES_MENU,
});
