import React from "react";
import ArticlesList from "../ArticlesList/index";
import TotalGestion from "../TotalGestion/index";
import StatusGestion from "../StatusGestion/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "./index.scss";

type CommandOverviewProps = RouteComponentProps & {
  // Props
  urltoCheck?: string | string[];

  // Redux State
  focusedCommandArticlesList?: any;
};

class CommandOverview extends React.Component<CommandOverviewProps> {
  // Render methods chechkin URL
  renderCommandOverview(urlToCheck: string | string[]) {
    if (
      urlToCheck.includes("commands_new/details") ||
      urlToCheck.includes("commands_pending/details")
    ) {
      return (
        <React.Fragment>
          <TotalGestion />
          <StatusGestion />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <TotalGestion />
        </React.Fragment>
      );
    }
  }

  // Component render
  render() {
    const urlToCheck = this.props.location.pathname;
    const { focusedCommandArticlesList } = this.props;
    return (
      <div className="CommandOverview">
        {urlToCheck.includes("commands_pending/details") ? (
          <ArticlesList
            dataArticlesList={focusedCommandArticlesList}
            canBeDeletedFromCommand
          />
        ) : (
          <ArticlesList dataArticlesList={focusedCommandArticlesList} />
        )}

        {this.renderCommandOverview(urlToCheck)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  focusedCommandArticlesList:
    state.commands.focusedCommand.focusedCommandArticlesList,
});

export default withRouter(connect(mapStateToProps)(CommandOverview));
