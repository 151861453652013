import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";

import { getFocusedCommand } from "../../actions/commands.actions";

type ToasterLinkProps = {
  // Pusher data
  id_order?: any;

  // Redux Actions
  getFocusedCommand?: any;
};

class ToasterLink extends React.Component<ToasterLinkProps> {
  render() {
    const { id_order, getFocusedCommand } = this.props;
    return (
      <div
        className="ToasterContaner"
        onClick={() => getFocusedCommand(id_order)}
      >
        <Link to={`/commands_new/details/${id_order}`} className="toasterLink">
          Nouvelle commande à traiter!
        </Link>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFocusedCommand: (id_order) => dispatch(getFocusedCommand(id_order)),
});

export default connect(null, mapDispatchToProps)(ToasterLink);
