import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import "./index.scss";

import {
  toggleConfirmButton,
  resetConfirmButton,
  patchFocusedCommandToPending,
  patchFocusedCommandToHistory,
} from "../../actions/commands.actions";
import {
  deleteArticleFromStore,
  hideDeleteArticlePopup,
} from "../../actions/articles.actions";

type StatusGestionProps = RouteComponentProps & {
  // Props
  urlToCheck?: string | string[];

  // Redux State
  isConfirmButtonEnabled?: boolean;
  articleToDelete?: any;
  focusedCommandInfos?: any;

  // Redux Actions
  toggleConfirmButton?: any;
  resetConfirmButton?: any;
  patchFocusedCommandToPending?: any;
  patchFocusedCommandToHistory?: any;
  hideDeleteArticlePopup?: any;
  deleteArticleFromStore?: any;
};

class StatusGestion extends React.Component<StatusGestionProps> {
  // Component Lifecycle
  componentWillUnmount() {
    const { resetConfirmButton } = this.props;
    resetConfirmButton();
  }

  // Component render gestion checking URL
  renderStatusGestion(urlToCheck: string | string[]) {
    const {
      toggleConfirmButton,
      isConfirmButtonEnabled,
      articleToDelete,
      focusedCommandInfos,
      hideDeleteArticlePopup,
      deleteArticleFromStore,
      patchFocusedCommandToPending,
      patchFocusedCommandToHistory,
    } = this.props;
    if (urlToCheck.includes("commands_new/details")) {
      return (
        <React.Fragment>
          <button
            id="readyButton"
            onClick={() => toggleConfirmButton(!isConfirmButtonEnabled)}
          >
            {isConfirmButtonEnabled === true
              ? "Annuler"
              : "Prêt pour le retrait"}
          </button>
          <button
            id="confirmButton"
            className={isConfirmButtonEnabled === true ? "enabled" : "disabled"}
            onClick={() =>
              patchFocusedCommandToPending(focusedCommandInfos.id_order)
            }
          >
            Confirmer
          </button>
        </React.Fragment>
      );
    } else if (urlToCheck.includes("commands_pending/details")) {
      return (
        <React.Fragment>
          <button
            id="paidButton"
            onClick={() => toggleConfirmButton(!isConfirmButtonEnabled)}
          >
            {isConfirmButtonEnabled === true ? "Annuler" : "Commande réglée"}
          </button>
          <button
            id="confirmButton"
            className={isConfirmButtonEnabled === true ? "enabled" : "disabled"}
            onClick={() =>
              patchFocusedCommandToHistory(focusedCommandInfos.id_order)
            }
          >
            Confirmer
          </button>
        </React.Fragment>
      );
    } else if (urlToCheck.includes("store")) {
      return (
        <React.Fragment>
          <button
            onClick={() => {
              hideDeleteArticlePopup();
            }}
          >
            Annuler
          </button>

          <button
            id="confirmButton"
            className={"enabled"}
            onClick={() =>
              deleteArticleFromStore(articleToDelete[0].id_rel_stores_products)
            }
          >
            Confirmer
          </button>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  // Component Render
  render() {
    const urlToCheck = this.props.location.pathname;

    return (
      <div className="StatusGestion">
        {this.renderStatusGestion(urlToCheck)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isConfirmButtonEnabled: state.commands.isConfirmButtonEnabled,
  articleToDelete: state.articles.articleToDelete,
  focusedCommandInfos: state.commands.focusedCommand.focusedCommandInfos,
});

const mapDispatchToProps = (dispatch) => ({
  toggleConfirmButton: () => dispatch(toggleConfirmButton()),
  resetConfirmButton: () => dispatch(resetConfirmButton()),
  hideDeleteArticlePopup: () => dispatch(hideDeleteArticlePopup()),
  deleteArticleFromStore: (id_rel_stores_products) =>
    dispatch(deleteArticleFromStore(id_rel_stores_products)),
  patchFocusedCommandToPending: (id_order) =>
    dispatch(patchFocusedCommandToPending(id_order)),
  patchFocusedCommandToHistory: (id_order) =>
    dispatch(patchFocusedCommandToHistory(id_order)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatusGestion)
);
