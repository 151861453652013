import { fetchLoginFromDB, fetchLogoutFromDB } from "../api/user.api";
import * as userConst from "../const/user.const";

export const login = (username, password, type) => ({
  type: userConst.FETCH_LOGIN,
  payload: fetchLoginFromDB(username, password, type),
});

export const logout = (id_store) => ({
  type: userConst.FETCH_LOGOUT,
  payload: fetchLogoutFromDB(id_store),
});
