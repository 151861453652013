import { takeEvery } from "redux-saga/effects";
import { loginLogic } from "./user.saga";
import { closeCategoryMenuLogic } from "./categories.saga";
import { toggleActiveStoreTabLogic } from "./store.saga";
import {
  toastifyNewCommandLogic,
  commandToPendingLogic,
  commandToCanceledLogic,
  commandToHistoryLogic,
  searchCommandsQueryLogic,
} from "./commands.saga";

import {
  handleDbFetchLogic,
  addNewArticleToStoreLogic,
  deleteArticleFromStoreLogic,
  deleteArticleFromCommandLogic,
  exitNewArticlePopupLogic,
  searchArticlesQueryLogic,
} from "./articles.saga";
import { storeInitLogic } from "./logic.saga";

export default function* rootSaga() {
  // --------------- LOGIN/OUT LOGIC ---------------
  yield takeEvery("FETCH_LOGIN_FULFILLED", loginLogic);

  // --------------- STORE LOGIC ---------------
  yield takeEvery("TOGGLE_ACTIVE_STORE_TAB", toggleActiveStoreTabLogic);

  // // CATEGORIES LOGIC
  yield takeEvery("HANDLE_CATEGORIES_SELECTION", closeCategoryMenuLogic);
  yield takeEvery("HANDLE_CATEGORIES_RESET", closeCategoryMenuLogic);

  // // ARTICLES LOGIC
  yield takeEvery("STORE_INIT", storeInitLogic);
  yield takeEvery("HANDLE_INPUT_EAN", handleDbFetchLogic);
  yield takeEvery("EXIT_NEW_ARTICLE_POPUP", exitNewArticlePopupLogic);
  yield takeEvery("SEARCH_ARTICLES_QUERY_FULFILLED", searchArticlesQueryLogic);
  yield takeEvery(
    "ADD_NEW_ARTICLE_TO_STORE_FULFILLED",
    addNewArticleToStoreLogic
  );
  yield takeEvery(
    "DELETE_ARTICLE_FROM_STORE_FULFILLED",
    deleteArticleFromStoreLogic
  );

  // --------------- COMMANDS LOGIC ---------------
  yield takeEvery("TOASTIFY_NEW_COMMAND", toastifyNewCommandLogic);
  yield takeEvery("SEARCH_COMMANDS_QUERY_FULFILLED", searchCommandsQueryLogic);
  yield takeEvery(
    "DELETE_ARTICLE_FROM_COMMAND_FULFILLED",
    deleteArticleFromCommandLogic
  );
  yield takeEvery(
    "PATCH_FOCUSED_COMMAND_TO_PENDING_FULFILLED",
    commandToPendingLogic
  );
  yield takeEvery(
    "PATCH_FOCUSED_COMMAND_TO_CANCELED_FULFILLED",
    commandToCanceledLogic
  );
  yield takeEvery(
    "PATCH_FOCUSED_COMMAND_TO_HISTORY_FULFILLED",
    commandToHistoryLogic
  );
}
