import React from "react";
import CommandStatus from "../CommandStatus/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment/moment.js";
import "moment/locale/fr";
import "moment-timezone";

import "./index.scss";

import { getFocusedCommand } from "../../actions/commands.actions";

type CommandLineProps = {
  // Props;
  commandClassName?: string;
  commandData?: any;

  // Redux Actions
  getFocusedCommand?: any;
};

class CommandLine extends React.Component<CommandLineProps> {
  render() {
    const { commandClassName, commandData, getFocusedCommand } = this.props;
    let url = "";
    let status = "";
    let message = "";

    if (commandData.id_order_statement === 1) {
      url = "/commands_new/details";
      status = "newCommand";
      message = "nouvelle";
    } else if (commandData.id_order_statement === 2) {
      url = "/commands_pending/details";
      status = "pendingCommand";
      message = "en attente";
    } else if (commandData.id_order_statement === 3) {
      url = "/commands_history/details";
      status = "validatedCommand";
      message = "validée";
    } else if (
      commandData.id_order_statement === 4 ||
      commandData.id_order_statement === 5
    ) {
      url = "/commands_canceled/details";
      status = "canceledCommand";
      message = "annulée";
    }
    return (
      <li
        className={commandClassName}
        onClick={() => getFocusedCommand(commandData.id_order)}
      >
        {commandData.hasOwnProperty("id_order") === false ? null : (
          <Link to={`${url}/${commandData.id_order}`}>
            <strong className="commandNumber">{commandData.id_order}</strong>
            <p className="clientName">
              {commandData.user_lastname} {commandData.user_firstname}
            </p>
            <p className="commandDate">
              {moment(commandData.order_date).locale("fr").format("LL")} à{" "}
              {moment(commandData.order_date).locale("fr").format("LT")}
            </p>
            <p className="commandArticlesCount">
              {commandData.articles_count} articles
            </p>
            <CommandStatus commandStatus={status} commandMessage={message} />
          </Link>
        )}
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFocusedCommand: (id_order) => dispatch(getFocusedCommand(id_order)),
});

export default connect(null, mapDispatchToProps)(CommandLine);
