import React from "react";
import CloseButton from "../CloseButton/index";
import { connect } from "react-redux";
import "./index.scss";

import { patchFocusedCommandToCanceled } from "../../actions/commands.actions";

type ManageMenuProps = {
  // Redux State
  isManageMenuOpen?: boolean;
  focusedCommandInfos?: any;

  // Redux Actions
  patchFocusedCommandToCanceled?: any;
};

class ManageMenu extends React.Component<ManageMenuProps> {
  // Component render
  render() {
    const {
      isManageMenuOpen,
      focusedCommandInfos,
      patchFocusedCommandToCanceled,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className={
            isManageMenuOpen
              ? "manageOverlay overManageShown"
              : "manageOverlay overManageHidden"
          }
        ></div>
        <div
          className={
            isManageMenuOpen
              ? "ManageMenu manageShown"
              : "ManageMenu manageHidden"
          }
        >
          <div className="manageMenuList">
            <div className="manageLabel">
              <CloseButton />
              <h3>Gérer la commande</h3>
            </div>
            <ul className="manageActionsList">
              <li className="manageActionsItem">
                <button
                  className="manageActionButton"
                  onClick={() =>
                    patchFocusedCommandToCanceled(focusedCommandInfos.id_order)
                  }
                >
                  Annuler la commande
                  <img
                    src="/img/manage_action_arrow.png"
                    alt="Flêche Action Suivante"
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isManageMenuOpen: state.menus.isManageMenuOpen,
  focusedCommandInfos: state.commands.focusedCommand.focusedCommandInfos,
});

const mapDispatchToProps = (dispatch) => ({
  patchFocusedCommandToCanceled: (id_order) =>
    dispatch(patchFocusedCommandToCanceled(id_order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageMenu);
