import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import axios from "axios";

import Main from "./pages/Main";
import "./index.scss";

import { unregister } from "./serviceWorker";
unregister();

axios.defaults.withCredentials = true;
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const { token } = store.getState().user;

    if (token !== null) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      config.headers.common["Authorization"] = null;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Catch UNAUTHORIZED response
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch({
        type: "FETCH_UNAUTHORIZED",
        error: error.response,
      });
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Main />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
