import * as articlesConst from "../const/articles.const";
import {
  getLatestArticlesFromDB,
  addNewArticleToStoreInDB,
  searchArticlesQueryToDB,
  deleteArticleFromStoreInDB,
  deleteArticleFromCommandInDB,
} from "../api/articles.api";

export const getLatestArticles = (latestArticlePage) => ({
  type: articlesConst.GET_LATEST_ARTICLES,
  payload: getLatestArticlesFromDB(latestArticlePage),
});

export const emptyLatestArticles = () => ({
  type: articlesConst.EMPTY_LATEST_ARTICLES,
});

export const showUnknownArticlePopup = () => ({
  type: articlesConst.SHOW_UNKNOWN_ARTICLE_POPUP,
  payload: document.body.classList.add("noScroll"),
});

export const hideUnknownArticlePopup = () => ({
  type: articlesConst.HIDE_UNKNOWN_ARTICLE_POPUP,
  payload: document.body.removeAttribute("class"),
});

export const showNewArticlePopup = () => ({
  type: articlesConst.SHOW_NEW_ARTICLE_POPUP,
});

export const hideNewArticlePopup = () => ({
  type: articlesConst.HIDE_NEW_ARTICLE_POPUP,
});

export const exitNewArticlePopup = () => ({
  type: articlesConst.EXIT_NEW_ARTICLE_POPUP,
});

export const showDeleteArticlePopup = (article_to_delete) => ({
  type: articlesConst.SHOW_DELETE_ARTICLE_POPUP,
  payload: article_to_delete,
});

export const hideDeleteArticlePopup = () => ({
  type: articlesConst.HIDE_DELETE_ARTICLE_POPUP,
  payload: document.body.removeAttribute("class"),
});

export const showDeleteArticleFromCommandPopup = (
  article_to_delete_from_command
) => ({
  type: articlesConst.SHOW_DELETE_ARTICLE_FROM_COMMAND_POPUP,
  payload: article_to_delete_from_command,
});

export const hideDeleteArticleFromCommandPopup = () => ({
  type: articlesConst.HIDE_DELETE_ARTICLE_FROM_COMMAND_POPUP,
  payload: document.body.removeAttribute("class"),
});

export const handleInputEan = () => ({
  type: articlesConst.HANDLE_INPUT_EAN,
});

export const fetchNewArticleInfosInReducer = (product_data) => ({
  type: articlesConst.FETCH_NEW_ARTICLE_INFOS_IN_REDUCER,
  payload: product_data,
});

export const emptyNewArticleInfosInReducer = () => ({
  type: articlesConst.EMPTY_NEW_ARTICLE_INFOS_IN_REDUCER,
});

export const addNewArticleToStore = (
  article_ean,
  id_category,
  article_title,
  article_price,
  article_cover,
  article_pictures,
  id_organization
) => ({
  type: articlesConst.ADD_NEW_ARTICLE_TO_STORE,
  payload: addNewArticleToStoreInDB(
    article_ean,
    id_category,
    article_title,
    article_price,
    article_cover,
    article_pictures,
    id_organization
  ),
});

export const deleteArticleFromStore = (id_rel_stores_products) => ({
  type: articlesConst.DELETE_ARTICLE_FROM_STORE,
  payload: deleteArticleFromStoreInDB(id_rel_stores_products),
});

export const deleteArticleFromCommand = (id_rel_orders_products) => ({
  type: articlesConst.DELETE_ARTICLE_FROM_COMMAND,
  payload: deleteArticleFromCommandInDB(id_rel_orders_products),
});

export const searchArticlesQuery = (id_category, title, searchArticlePage) => ({
  type: articlesConst.SEARCH_ARTICLES_QUERY,
  payload: searchArticlesQueryToDB(id_category, title, searchArticlePage),
});

export const emptySearchArticlesQuery = () => ({
  type: articlesConst.EMPTY_SEARCH_ARTICLES_QUERY,
});
