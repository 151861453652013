import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { showDeleteArticleFromCommandPopup } from "../../actions/articles.actions";

type SupressButtonProps = {
  // Component Props
  dataArticle?: any;

  // Redux Actions
  showDeleteArticleFromCommandPopup?: any;
};

class SupressButton extends React.Component<SupressButtonProps> {
  render() {
    const { dataArticle, showDeleteArticleFromCommandPopup } = this.props;
    return (
      <div className="SupressButton">
        <button onClick={() => showDeleteArticleFromCommandPopup(dataArticle)}>
          <img src="/img/suppress_icon.png" alt="Icone suppression d'article" />
          Supprimer
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showDeleteArticleFromCommandPopup: (article_to_delete_from_command) =>
    dispatch(showDeleteArticleFromCommandPopup(article_to_delete_from_command)),
});

export default connect(null, mapDispatchToProps)(SupressButton);
