import React from "react";
import BackButton from "../BackButton/index";
import ManageButton from "../ManageButton/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import "./index.scss";

type PageHeaderProps = RouteComponentProps & {
  // Props
  title?: string;
  urlToCheck?: string | string[];
};

class PageHeader extends React.Component<PageHeaderProps> {
  // Render method checking URL
  renderPageHeader(urlToCheck) {
    const { title } = this.props;
    if (urlToCheck.includes("commands_new/details")) {
      return (
        <React.Fragment>
          <BackButton backButtonUrl="/commands_new" />
          <h2 className="pageHeader">{title}</h2>
          <ManageButton />
        </React.Fragment>
      );
    } else if (urlToCheck.includes("commands_pending/details")) {
      return (
        <React.Fragment>
          <BackButton backButtonUrl="/commands_pending" />
          <h2 className="pageHeader">{title}</h2>
          <ManageButton />
        </React.Fragment>
      );
    } else if (urlToCheck.includes("commands_canceled/details")) {
      return (
        <React.Fragment>
          <BackButton backButtonUrl="/commands_canceled" />
          <h2 className="pageHeader">{title}</h2>
        </React.Fragment>
      );
    } else if (urlToCheck.includes("commands_history/details")) {
      return (
        <React.Fragment>
          <BackButton backButtonUrl="/commands_history" />
          <h2 className="pageHeader">{title}</h2>
        </React.Fragment>
      );
    } else {
      return <h2 className="pageHeader">{title}</h2>;
    }
  }

  // Component render
  render() {
    const urlToCheck = this.props.location.pathname;

    return (
      <div className="PageHeaderContainer">
        {this.renderPageHeader(urlToCheck)}
      </div>
    );
  }
}

export default withRouter(PageHeader);
