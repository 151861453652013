import * as categoriesConst from "../const/categories.const";
import * as userConst from "../const/user.const";

const initState = {
  allCategories: [],
  activeCategoryId: 1,
  chosenCategoryId: null,
  chosenCategoryName: "",
};

const categories = (state = initState, action) => {
  switch (action.type) {
    case categoriesConst.GET_ALL_CATEGORIES_FULFILLED:
      return { ...state, allCategories: action.payload.data };

    case categoriesConst.HANDLE_CATEGORIES_BACK_BUTTON:
      for (let i = 0; i < state.allCategories.length; i++) {
        if (state.allCategories[i].id_category === action.payload) {
          return {
            ...state,
            activeCategoryId: state.allCategories[i].id_parent,
          };
        }
      }
      break;

    case categoriesConst.HANDLE_CATEGORIES_NAVIGATION:
      return { ...state, activeCategoryId: action.payload };

    case categoriesConst.HANDLE_CATEGORIES_SELECTION:
      return {
        ...state,
        chosenCategoryId: action.payload.id_category,
        chosenCategoryName: action.payload.category_name,
      };

    case categoriesConst.HANDLE_CATEGORIES_RESET:
      return {
        ...state,
        activeCategoryId: 1,
        chosenCategoryId: null,
        chosenCategoryName: "",
      };

    case userConst.FETCH_LOGOUT_FULFILLED:
      return { ...initState };

    default:
      return state;
  }
};

export default categories;
