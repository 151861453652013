import React from "react";
import PageHeader from "../../components/PageHeader/index";
import CommandsLabels from "../../components/CommandsLabels/index";
import CommandsList from "../../components/CommandsList/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "./index.scss";

import {
  getLatestPendingCommands,
  emptyLatestPendingCommands,
  commandsLoading,
  commandsNull,
} from "../../actions/commands.actions";

type CommandsPendingProps = RouteComponentProps & {
  // Redux State
  latestPendingCommands?: any;
  latestPendingCommandsPage?: any;
  commandsAreLoading?: any;

  // Redux Action
  getLatestPendingCommands?: any;
  emptyLatestPendingCommands?: any;
  commandsLoading?: any;
  commandsNull?: any;
};

class CommandsPending extends React.Component<CommandsPendingProps> {
  async componentDidMount() {
    const {
      getLatestPendingCommands,
      emptyLatestPendingCommands,
      commandsLoading,
    } = this.props;
    await commandsLoading();
    await emptyLatestPendingCommands();
    await getLatestPendingCommands();
  }

  async componentWillUnmount() {
    const { emptyLatestPendingCommands, commandsNull } = this.props;
    await commandsNull();
    await emptyLatestPendingCommands();
  }

  render() {
    const {
      getLatestPendingCommands,
      latestPendingCommands,
      latestPendingCommandsPage,
      commandsAreLoading,
    } = this.props;

    return (
      <div className="CommandsPending center">
        <PageHeader title="Commandes en attente" />
        <CommandsLabels />
        {commandsAreLoading === false && latestPendingCommands.length === 0 ? (
          <div className="emptyPendingCommands">
            <img
              src="/img/empty_commands_icon.png"
              alt="Icone pas de commandes en attente"
            />
            <strong>Aucune commande en attente</strong>
            <p>Les commandes en attente de préparation aparaitront ici.</p>
          </div>
        ) : (
          <CommandsList
            commandsData={latestPendingCommands}
            isInfiniteScroll
            infiniteScrollRefresh={() =>
              getLatestPendingCommands(latestPendingCommandsPage)
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  latestPendingCommands: state.commands.latestPendingCommands,
  latestPendingCommandsPage: state.commands.latestPendingCommandsPage,
  commandsAreLoading: state.commands.commandsAreLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getLatestPendingCommands: (latestPendingCommandsPage) =>
    dispatch(getLatestPendingCommands(latestPendingCommandsPage)),
  emptyLatestPendingCommands: () => dispatch(emptyLatestPendingCommands()),
  commandsLoading: () => dispatch(commandsLoading()),
  commandsNull: () => dispatch(commandsNull()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommandsPending)
);
