import axios from "axios";

export const fetchLoginFromDB = (username, password) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/auth`, {
    username,
    password,
  });

export const fetchLogoutFromDB = (id_store) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/logout`, { id_store });
