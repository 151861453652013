import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { toggleCategoriesMenu } from "../../actions/menus.actions";
import {
  handleCategoriesBackButton,
  handleCategoriesNavigation,
  handleCategoriesSelection,
  handleCategoriesReset,
} from "../../actions/categories.actions";

type CategoriesSelectorProps = {
  // Redux state
  allCategories?: any;
  activeCategoryId?: number;
  chosenCategoryId?: number;
  chosenCategoryName?: string;
  isCategoriesMenuOpen?: boolean;

  // Redux actions
  toggleCategoriesMenu?: any;
  handleCategoriesBackButton?: any;
  handleCategoriesNavigation?: any;
  handleCategoriesSelection?: any;
  handleCategoriesReset?: any;
};

class CategoriesSelector extends React.Component<CategoriesSelectorProps> {
  // Component render
  render() {
    const {
      allCategories,
      activeCategoryId,
      chosenCategoryId,
      chosenCategoryName,
      toggleCategoriesMenu,
      isCategoriesMenuOpen,
      handleCategoriesBackButton,
      handleCategoriesNavigation,
      handleCategoriesSelection,
      handleCategoriesReset,
    } = this.props;
    return (
      <div className="CategoriesSelectorContainer">
        {/* ---------- CATEGORY CHOSEN ---------- */}
        <div
          className={
            chosenCategoryId === null
              ? "CategoriesSelector null"
              : "CategoriesSelector chosen"
          }
          onClick={() => toggleCategoriesMenu(!isCategoriesMenuOpen)}
        >
          {chosenCategoryId === null
            ? "Selectionnez une catégorie"
            : chosenCategoryName}
        </div>

        {/* ---------- CATEGORIES LIST ---------- */}
        <div
          className={
            isCategoriesMenuOpen
              ? "CategoriesList categoriesListShown"
              : "CategoriesList categoriesListHidden"
          }
        >
          {allCategories.length === 0
            ? null
            : allCategories
                .filter((item) => {
                  return item.id_category === activeCategoryId;
                })
                .map((item, key) => {
                  return activeCategoryId === 1 ? (
                    <div key={key} className="categoriesListHead">
                      <h3 className="firstCategoryListHead">Categories</h3>
                      {chosenCategoryId === null ? null : (
                        <button
                          className="resetButton"
                          onClick={() => handleCategoriesReset()}
                        >
                          reset
                        </button>
                      )}
                    </div>
                  ) : (
                    <div key={key} className="categoriesListHead">
                      <div
                        className="categoriesNavigation"
                        onClick={() =>
                          handleCategoriesBackButton(activeCategoryId)
                        }
                      >
                        <img
                          src="/img/select_category_arrow.svg"
                          alt="Flêche Retour"
                        />
                        <h3>{item.name}</h3>
                      </div>

                      {chosenCategoryId === null ? null : (
                        <button
                          className="resetButton"
                          onClick={() => handleCategoriesReset()}
                        >
                          reset
                        </button>
                      )}
                    </div>
                  );
                })}
          <ul>
            {allCategories.length === 0
              ? null
              : allCategories
                  .filter((item) => {
                    return item.id_parent === activeCategoryId;
                  })
                  .map((item, key) => {
                    if (item.nb_childrens === "0") {
                      return (
                        <li
                          className="selectItem"
                          key={key}
                          onClick={() =>
                            handleCategoriesSelection(
                              item.id_category,
                              item.name
                            )
                          }
                        >
                          {item.name}
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={key}
                          onClick={() =>
                            handleCategoriesNavigation(item.id_category)
                          }
                        >
                          {item.name}
                          <img
                            src="/img/forward_arrow.svg"
                            alt="Flêche Navigation"
                          />
                        </li>
                      );
                    }
                  })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allCategories: state.categories.allCategories,
  activeCategoryId: state.categories.activeCategoryId,
  chosenCategoryId: state.categories.chosenCategoryId,
  chosenCategoryName: state.categories.chosenCategoryName,
  isCategoriesMenuOpen: state.menus.isCategoriesMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCategoriesMenu: () => dispatch(toggleCategoriesMenu()),
  handleCategoriesNavigation: (id_category) =>
    dispatch(handleCategoriesNavigation(id_category)),
  handleCategoriesSelection: (id_category, category_name) =>
    dispatch(handleCategoriesSelection(id_category, category_name)),
  handleCategoriesReset: () => dispatch(handleCategoriesReset()),
  handleCategoriesBackButton: (id_category) =>
    dispatch(handleCategoriesBackButton(id_category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSelector);
