import React from "react";
import CategoriesSelector from "../CategoriesSelector/index";
import StoreInput from "../StoreInput/index";
import { connect } from "react-redux";
import "./index.scss";

import {
  searchArticlesQuery,
  emptySearchArticlesQuery,
} from "../../actions/articles.actions";
import { setStoreInputFieldValue } from "../../actions/store.actions";

type SearchSetProps = {
  // Redux State
  chosenCategoryId?: number;
  storeInputFieldValue?: string;

  // Redux Actions
  setStoreInputFieldValue?: any;
  emptySearchArticlesQuery?: any;
  searchArticlesQuery?: any;
};

class SearchSet extends React.Component<SearchSetProps> {
  handleSubmit = (event) => {
    const {
      chosenCategoryId,
      storeInputFieldValue,
      emptySearchArticlesQuery,
      searchArticlesQuery,
    } = this.props;
    event.preventDefault();
    emptySearchArticlesQuery();
    searchArticlesQuery(chosenCategoryId, storeInputFieldValue);
  };

  handleSearch = (event) => {
    const { setStoreInputFieldValue } = this.props;
    setStoreInputFieldValue(event.target.value);
  };

  render() {
    return (
      <div className="SearchSet">
        <CategoriesSelector />
        <form className="searchSetForm" onSubmit={this.handleSubmit}>
          <StoreInput
            idStoreInput={"searchSetInput"}
            typeStoreInput={"text"}
            placeholderStoreInput={"Que recherchez-vous ?"}
            storeInputFillMethod={this.handleSearch}
          />

          <button id="searchSetButton" type="submit">
            Rechercher
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chosenCategoryId: state.categories.chosenCategoryId,
  storeInputFieldValue: state.store.storeInputFieldValue,
});

const mapDispatchToProps = (dispatch) => ({
  searchArticlesQuery: (id_category, title) =>
    dispatch(searchArticlesQuery(id_category, title)),
  setStoreInputFieldValue: (input_value) =>
    dispatch(setStoreInputFieldValue(input_value)),
  emptySearchArticlesQuery: () => dispatch(emptySearchArticlesQuery()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchSet);
