import { put, call } from "redux-saga/effects";
import { store } from "../store";
import { handleCategoriesReset } from "../actions/categories.actions";
import { emptyStoreInputFieldValue } from "../actions/store.actions";
import {
  getLatestArticles,
  showNewArticlePopup,
  hideNewArticlePopup,
  showUnknownArticlePopup,
  fetchNewArticleInfosInReducer,
  emptyNewArticleInfosInReducer,
  hideDeleteArticlePopup,
  emptyLatestArticles,
  hideDeleteArticleFromCommandPopup,
} from "../actions/articles.actions";

import { addArticleToStoreFromDB } from "../api/articles.api";
import { getFocusedCommand } from "../actions/commands.actions";

export function* handleDbFetchLogic(action) {
  try {
    const storeInputFieldValue = yield store.getState().store
      .storeInputFieldValue;

    const product = yield call(addArticleToStoreFromDB, {
      ...action,
      payload: { article_ean: storeInputFieldValue },
    });

    if (product.data[0].id_category === undefined) {
      yield put(fetchNewArticleInfosInReducer(product.data[0]));
      yield put(emptyStoreInputFieldValue());
      yield put(showNewArticlePopup());
    } else {
      yield put(emptyStoreInputFieldValue());
      yield put(emptyLatestArticles());
      yield put(getLatestArticles());
    }
  } catch (error) {
    yield put(emptyStoreInputFieldValue());
    yield put(showUnknownArticlePopup());
    console.log(error);
  }
}

export function* addNewArticleToStoreLogic() {
  yield put(hideNewArticlePopup());
  yield put(handleCategoriesReset());
  yield put(emptyNewArticleInfosInReducer());
  yield put(emptyLatestArticles());
  yield put(getLatestArticles());
}

export function* deleteArticleFromStoreLogic() {
  yield put(hideDeleteArticlePopup());
  yield put(emptyLatestArticles());
  yield put(getLatestArticles());
}

export function* deleteArticleFromCommandLogic() {
  const focusedCommandId = yield store.getState().commands.focusedCommand
    .focusedCommandInfos.id_order;
  yield put(getFocusedCommand(focusedCommandId));
  yield put(hideDeleteArticleFromCommandPopup());
}

export function* exitNewArticlePopupLogic() {
  yield put(emptyNewArticleInfosInReducer());
  yield put(handleCategoriesReset());
}

export function* searchArticlesQueryLogic() {
  yield put(emptyStoreInputFieldValue());
  yield put(handleCategoriesReset());
}
