import React from "react";
import PageHeader from "../../components/PageHeader/index";
import CommandsLabels from "../../components/CommandsLabels/index";
import CommandsList from "../../components/CommandsList/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "./index.scss";

import {
  getLatestHistoryCommands,
  emptyLatestHistoryCommands,
  commandsLoading,
  commandsNull,
} from "../../actions/commands.actions";

type CommandsHistoryProps = RouteComponentProps & {
  // Redux State
  latestHistoryCommands?: any;
  latestHistoryCommandsPage?: any;
  commandsAreLoading?: any;

  // Redux Actions
  getLatestHistoryCommands?: any;
  emptyLatestHistoryCommands?: any;
  commandsLoading?: any;
  commandsNull?: any;
};

class CommandsHistory extends React.Component<CommandsHistoryProps> {
  async componentDidMount() {
    const {
      emptyLatestHistoryCommands,
      getLatestHistoryCommands,
      commandsLoading,
    } = this.props;
    await commandsLoading();
    await emptyLatestHistoryCommands();
    await getLatestHistoryCommands();
  }

  async componentWillUnmount() {
    const { emptyLatestHistoryCommands, commandsNull } = this.props;
    await commandsNull();
    await emptyLatestHistoryCommands();
  }

  render() {
    const {
      getLatestHistoryCommands,
      latestHistoryCommands,
      latestHistoryCommandsPage,
      commandsAreLoading,
    } = this.props;

    return (
      <div className="CommandsHistory center">
        <PageHeader title="Historique des commandes" />
        <CommandsLabels />
        {commandsAreLoading === false && latestHistoryCommands.length === 0 ? (
          <div className="emptyCommandsHistory">
            <img
              src="/img/empty_commands_history_icon.png"
              alt="Icone pas d'historique de commandes"
            />
            <strong>Aucun historique de commande</strong>
            <p>
              Les commandes qui ont été réglées et récupérées apparaitront ici.
            </p>
          </div>
        ) : (
          <CommandsList
            commandsData={latestHistoryCommands}
            isInfiniteScroll
            infiniteScrollRefresh={() =>
              getLatestHistoryCommands(latestHistoryCommandsPage)
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  latestHistoryCommands: state.commands.latestHistoryCommands,
  latestHistoryCommandsPage: state.commands.latestHistoryCommandsPage,
  commandsAreLoading: state.commands.commandsAreLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getLatestHistoryCommands: (latestHistoryCommandsPage) =>
    dispatch(getLatestHistoryCommands(latestHistoryCommandsPage)),
  emptyLatestHistoryCommands: () => dispatch(emptyLatestHistoryCommands()),
  commandsLoading: () => dispatch(commandsLoading()),
  commandsNull: () => dispatch(commandsNull()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommandsHistory)
);
