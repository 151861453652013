import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { emptyStoreInputFieldValue } from "../../actions/store.actions";

type StoreInputProps = {
  // Props
  idStoreInput?: string;
  typeStoreInput?: string;
  placeholderStoreInput?: string;
  storeInputFillMethod?: any;
  maxLength?: number;

  // Redux State
  storeInputFieldValue?: any;
  ean_length?: any;

  // Redux Actions
  emptyStoreInputFieldValue?: any;
};

class StoreInput extends React.Component<StoreInputProps> {
  // Component render
  render() {
    const {
      idStoreInput,
      typeStoreInput,
      placeholderStoreInput,
      storeInputFillMethod,
      storeInputFieldValue,
      emptyStoreInputFieldValue,
      ean_length,
      maxLength,
    } = this.props;

    return (
      <div className={`${idStoreInput}Container`}>
        <input
          id={idStoreInput}
          type={typeStoreInput}
          placeholder={placeholderStoreInput}
          value={storeInputFieldValue}
          onChange={storeInputFillMethod}
          maxLength={maxLength}
        />

        {/* ERROR FEEDBACK BASED ON EAN TYPE / EAN LENGTH */}
        {ean_length !== undefined ? (
          idStoreInput === "scanSetInput" &&
          storeInputFieldValue.length !== ean_length &&
          storeInputFieldValue.length !== 0 ? (
            storeInputFieldValue.length < ean_length ? (
              <p className="feedback">
                <img src="/img/error_feedback.png" alt="icone erreur" />
                {ean_length - storeInputFieldValue.length} caractères manquants.
              </p>
            ) : storeInputFieldValue.length > ean_length ? (
              <p className="feedback">
                <img src="/img/error_feedback.png" alt="icone erreur" />
                {storeInputFieldValue.length - ean_length} caractères en trop.
              </p>
            ) : null
          ) : null
        ) : null}

        {/* EMPTY INPUT CROSS WHEN INPUT ISN4T EMPTY */}
        {idStoreInput === "scanSetInput" &&
        storeInputFieldValue.length !== 0 ? (
          <img
            src="./img/empty_input_icon.svg"
            alt="Icone vider l'input"
            className="emptyInputIcon"
            onClick={() => {
              emptyStoreInputFieldValue();
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  storeInputFieldValue: state.store.storeInputFieldValue,
  ean_length: state.user.ean_length,
});

const mapDispatchToProps = (dispatch) => ({
  emptyStoreInputFieldValue: () => dispatch(emptyStoreInputFieldValue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreInput);
