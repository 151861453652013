import React from "react";
import DeleteArticle from "../DeleteArticle/index";
import DeleteArticleFromCommand from "../DeleteArticleFromCommand/index";
import SupressButton from "../SupressButton/index";
import { connect } from "react-redux";
import "./index.scss";

import { showDeleteArticlePopup } from "../../actions/articles.actions";

type ArticleProps = {
  // Component props
  dataArticle?: any;
  canBeDeleted?: boolean;
  canBeDeletedFromCommand?: any;

  // Redux state
  ean_type?: string;
  focusedCommandArticlesCount?: any;

  // Redux Actions
  showDeleteArticlePopup?: any;
};

class Article extends React.Component<ArticleProps> {
  // Component render
  render() {
    const {
      dataArticle,
      canBeDeleted,
      canBeDeletedFromCommand,
      showDeleteArticlePopup,
      ean_type,
      focusedCommandArticlesCount,
    } = this.props;

    return (
      <li className="Article">
        <div className="articlePhoto">
          {dataArticle.cover === null ? (
            <img src="/img/article_photo.jpg" alt="Visuel Article" />
          ) : dataArticle.cover[0] === "/" ? (
            <img
              src={`${process.env.REACT_APP_ASSETS_HOST}${dataArticle.cover}`}
              alt="Visuel Article"
            />
          ) : (
            <img src={`${dataArticle.cover}`} alt="Visuel Article" />
          )}
        </div>

        <div className="articleDescription">
          <ul>
            <li>
              <p>Nom de l'article</p>
              <strong>{dataArticle.title}</strong>
            </li>
            <li>
              <p>Quantité</p>
              <strong>2</strong>
            </li>
            <li>
              <p>Prix unitaire</p>
              <strong>{dataArticle.price}€</strong>
            </li>
          </ul>

          <ul>
            <li>
              <p>Choix de la taille</p>
              <strong>XS</strong>
            </li>
          </ul>

          <ul>
            <li>
              <p>{ean_type} :</p>
              <strong>{dataArticle.ean}</strong>
            </li>
            {canBeDeletedFromCommand && focusedCommandArticlesCount > 1 ? (
              <li>
                <SupressButton dataArticle={dataArticle} />
                <DeleteArticleFromCommand />
              </li>
            ) : null}
          </ul>
        </div>

        {canBeDeleted ? (
          <div className="articleDeleteCross">
            <img
              src="/img/delete_cross_gray.png"
              alt="Croix suppression article"
              title="Supprimer du store"
              onClick={() => showDeleteArticlePopup(dataArticle)}
            />
            <DeleteArticle />
          </div>
        ) : null}
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  ean_type: state.user.ean_type,
  focusedCommandArticlesCount:
    state.commands.focusedCommand.focusedCommandInfos.articles_count,
});

const mapDispatchToProps = (dispatch) => ({
  showDeleteArticlePopup: (article_to_delete) =>
    dispatch(showDeleteArticlePopup(article_to_delete)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
