import axios from "axios";

export const getLatestArticlesFromDB = (latestArticlesPage) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/latest`, {
    latestArticlesPage,
  });

export const addArticleToStoreFromDB = (action) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/add`, {
    article_ean: action.payload.article_ean,
  });
};

export const addNewArticleToStoreInDB = (
  article_ean,
  id_category,
  article_title,
  article_price,
  article_cover,
  article_pictures,
  id_organization
) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/new`, {
    article_ean,
    id_category,
    article_title,
    article_price,
    article_cover,
    article_pictures,
    id_organization,
  });

export const deleteArticleFromStoreInDB = (id_rel_stores_products) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/delete`, {
    id_rel_stores_products,
  });

export const deleteArticleFromCommandInDB = (id_rel_orders_products) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/admin/commands/articles/delete`,
    {
      id_rel_orders_products,
    }
  );

export const searchArticlesQueryToDB = (
  id_category,
  title,
  searchArticlesPage
) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/search`, {
    id_category,
    title,
    searchArticlesPage,
  });
