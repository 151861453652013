import React from "react";
import Article from "../Article/index";
import StatusGestion from "../StatusGestion/index";
import { connect } from "react-redux";
import "./index.scss";

type DeleteArticleProps = {
  //Props
  articleToDelete?: any;

  // Redux State
  isDeleteArticlePopupShown?: any;
};

class DeleteArticle extends React.Component<DeleteArticleProps> {
  // Component render
  render() {
    const { articleToDelete, isDeleteArticlePopupShown } = this.props;

    return isDeleteArticlePopupShown ? (
      <div className="DeleteArticle">
        {isDeleteArticlePopupShown
          ? document.body.classList.add("noScroll")
          : null}
        <div className="deleteArticleContainer center">
          <div className="deleteArticleBanner">
            <p className="deleteArticleText">
              Voulez-vous vraiment supprimer{" "}
              <strong>{articleToDelete[0].title}</strong> de votre store ?
            </p>
            <ul className="deletArticleOverview">
              <Article dataArticle={articleToDelete[0]} />
            </ul>
            <StatusGestion />
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isDeleteArticlePopupShown: state.articles.isDeleteArticlePopupShown,
  articleToDelete: state.articles.articleToDelete,
});

export default connect(mapStateToProps)(DeleteArticle);
