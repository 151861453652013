import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { hideUnknownArticlePopup } from "../../actions/articles.actions";

type UnknownArticleProps = {
  // Redux State
  isUnknownArticlePopupShown?: boolean;

  // Redux Actions
  hideUnknownArticlePopup?: any;
};

class UnknownArticle extends React.Component<UnknownArticleProps> {
  // Component render
  render() {
    const { hideUnknownArticlePopup, isUnknownArticlePopupShown } = this.props;

    return isUnknownArticlePopupShown ? (
      <div className="UnknownArticle">
        {isUnknownArticlePopupShown
          ? document.body.classList.add("noScroll")
          : null}
        <div className="unknownArticleContainer center">
          <div className="unknownArticleBanner">
            <img
              className="closeCross"
              src="/img/close_cross_white.png"
              alt="Croix Fermeture"
              onClick={() => {
                document.body.removeAttribute("class");
                hideUnknownArticlePopup();
              }}
            />
            <div className="bannerText">
              <p className="bannerHead">Article Inconnu</p>
              <p>
                L’article que vous souhaitez ajouter n’existe pas dans notre
                base de donnée. Vous ne pouvez malheureusement pas l’ajouter à
                votre catalogue produit.
              </p>
            </div>
            <div className="bannerImage">
              <img
                src="/img/unknown_article_image.jpg"
                alt="VisuelArticle Inconnu"
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isUnknownArticlePopupShown: state.articles.isUnknownArticlePopupShown,
});

const mapDispatchToProps = (dispatch) => ({
  hideUnknownArticlePopup: () => dispatch(hideUnknownArticlePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnknownArticle);
