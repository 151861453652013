import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { openManageMenu } from "../../actions/menus.actions";

type CloseButtonProps = {
  // Redux Actions
  openManageMenu?: any;
};

class ManageButton extends React.Component<CloseButtonProps> {
  // Component render
  render() {
    const { openManageMenu } = this.props;

    return (
      <React.Fragment>
        <button className="ManageButton" onClick={() => openManageMenu()}>
          Gérer la commande
        </button>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openManageMenu: () => dispatch(openManageMenu()),
});

export default connect(null, mapDispatchToProps)(ManageButton);
