import React from "react";
import "./index.scss";

const CommandStatus = (props: {
  // Props
  commandStatus?: string;
  commandMessage?: string;
}) => {
  return (
    <div className="CommandStatus">
      <p className={props.commandStatus}>{props.commandMessage}</p>
    </div>
  );
};

export default CommandStatus;
