import React from "react";
import CommandsList from "../CommandsList/index";
import { connect } from "react-redux";
import "./index.scss";

import { emptySearchCommandsQuery } from "../../actions/commands.actions";

type StoreSearchOverviewProps = {
  // Redux State
  searchCommands?: any;

  // Redux Action
  emptySearchCommandsQuery?: any;
};

class StoreCommandsOverview extends React.Component<StoreSearchOverviewProps> {
  componentWillUnmount() {
    const { emptySearchCommandsQuery } = this.props;
    emptySearchCommandsQuery();
  }

  render() {
    const { searchCommands } = this.props;
    return (
      <div className="StoreCommandsOverview">
        <CommandsList commandsData={searchCommands} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchCommands: state.commands.searchCommands,
});

const mapsDispatchToProps = (dispatch) => ({
  emptySearchCommandsQuery: () => dispatch(emptySearchCommandsQuery()),
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(StoreCommandsOverview);
