import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { closeManageMenu } from "../../actions/menus.actions";

type CloseButtonProps = {
  // Redux Actions
  closeManageMenu?: any;
};

class CloseButton extends React.Component<CloseButtonProps> {
  // Component render
  render() {
    const { closeManageMenu } = this.props;

    return (
      <button className="CloseButton" onClick={() => closeManageMenu()}>
        <img src="/img/close_cross_black.png" alt="Bouton Fermeture" />
      </button>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeManageMenu: () => dispatch(closeManageMenu()),
});

export default connect(null, mapDispatchToProps)(CloseButton);
