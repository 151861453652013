import * as userConst from "../const/user.const";

const initState = {
  isLogged: false,
  logFeedback: null,
  token: null,
  id_users_dashboard: null,
  username: null,
  id_store: null,
  id_organization: null,
  id_city: null,
  organization_name: null,
  store_name: null,
  store_adress: null,
  city_name: null,
  city_department: null,
  city_postal: null,
  store_phone: null,
  store_latitude: null,
  store_longitude: null,
  ean_type: null,
  ean_length: null,
  type: null,
  disconnected_id_store: null,
};

const user = (state = initState, action) => {
  switch (action.type) {
    case userConst.FETCH_LOGIN_REJECTED:
      return {
        ...state,
        isLogged: false,
        logFeedback: action.payload.response.data.error,
      };

    case userConst.FETCH_LOGIN_FULFILLED:
      let temp_ean_length = state.ean_length;

      switch ((temp_ean_length, action.payload.data.userInfos.ean_type)) {
        case "EAN13":
          temp_ean_length = 13;
          break;
        case "ITF14":
          temp_ean_length = 14;
          break;
        case "CODE128":
          temp_ean_length = undefined;
          break;
        default:
          break;
      }

      return {
        ...state,
        isLogged: true,
        logFeedback: null,
        token: action.payload.data.token,
        id_users_dashboard: action.payload.data.userInfos.id_users_dashboard,
        username: action.payload.data.userInfos.username,
        id_store: action.payload.data.userInfos.id_store,
        id_organization: action.payload.data.userInfos.id_organization,
        id_city: action.payload.data.userInfos.id_city,
        organization_name: action.payload.data.userInfos.organization_name,
        store_name: action.payload.data.userInfos.store_name,
        store_adress: action.payload.data.userInfos.store_adress,
        city_name: action.payload.data.userInfos.city_name,
        city_department: action.payload.data.userInfos.city_department,
        city_postal: action.payload.data.userInfos.city_postal,
        store_phone: action.payload.data.userInfos.store_phone,
        store_latitude: action.payload.data.userInfos.store_latitude,
        store_longitude: action.payload.data.userInfos.store_longitude,
        ean_type: action.payload.data.userInfos.ean_type,
        ean_length: temp_ean_length,
        type: action.payload.data.userInfos.type,
        disconnected_id_store: null,
      };

    case userConst.FETCH_LOGOUT_FULFILLED:
      return { ...initState, disconnected_id_store: action.payload.data };

    default:
      return state;
  }
};

export default user;
