import * as storeConst from "../const/store.const";
import * as userConst from "../const/user.const";

const initState = {
  storeInputFieldValue: "",
};

const store = (state = initState, action) => {
  switch (action.type) {
    case storeConst.SET_STORE_INPUT_FIELD_VALUE:
      return { ...state, storeInputFieldValue: action.payload };

    case storeConst.EMPTY_STORE_INPUT_FIELD_VALUE:
      return { ...state, storeInputFieldValue: "" };

    case userConst.FETCH_LOGOUT_FULFILLED:
      return { ...initState };

    default:
      return state;
  }
};

export default store;
