import React from "react";
import CategoriesSelector from "../CategoriesSelector/index";
import { connect } from "react-redux";
import "./index.scss";

import {
  exitNewArticlePopup,
  addNewArticleToStore,
  emptyLatestArticles,
} from "../../actions/articles.actions";

type NewArticleProps = {
  // Redux State
  isNewArticlePopupShown?: boolean;
  chosenCategoryId?: number;
  articleToAdd?: any;
  ean_type?: string;

  // Redux actions
  addNewArticleToStore?: any;
  exitNewArticlePopup?: any;
  emptyLatestArticles?: any;
};

class NewArticle extends React.Component<NewArticleProps> {
  // Component render
  render() {
    const {
      isNewArticlePopupShown,
      chosenCategoryId,
      articleToAdd,
      ean_type,
      addNewArticleToStore,
      exitNewArticlePopup,
      emptyLatestArticles,
    } = this.props;

    return isNewArticlePopupShown ? (
      <React.Fragment>
        <div className="NewArticle">
          <img
            className="closeCross"
            src="/img/close_cross_black.png"
            alt="Croix Fermeture"
            onClick={() => exitNewArticlePopup()}
          />
          <p className="newArticleHead">Nouvel article</p>
          <p>
            Cet article n’a encore jamais été scanné.
            <br />
            Merci de renseigner la catégorie à laquelle il appartient.
          </p>

          <div className="newArticleContainer">
            <div className="newArticleCategory">
              <CategoriesSelector />
              {chosenCategoryId === null ? (
                <p className="categoryError">
                  <img src="/img/error_feedback.png" alt="Croix erreur" />
                  Veuillez spécifier une catégorie
                </p>
              ) : null}
            </div>
            <div className="newArticleInfos">
              <img src={articleToAdd.cover} alt="Visuel du nouvel artice" />
              <ul>
                <li>
                  <p>nom de l'article</p>
                  <strong>{articleToAdd.title}</strong>
                </li>
                <li>
                  <p>choix de la taille</p>
                  <strong>XS</strong>
                </li>
                <li>
                  <p>{ean_type}</p>
                  <strong>{articleToAdd.ean}</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="newArticleAddButton">
            <button
              className={
                chosenCategoryId === null
                  ? "disabledAddButton"
                  : "enabledAddButton"
              }
              onClick={() => {
                emptyLatestArticles();
                addNewArticleToStore(
                  articleToAdd.ean,
                  chosenCategoryId,
                  articleToAdd.title,
                  articleToAdd.price,
                  articleToAdd.cover,
                  articleToAdd.pictures,
                  articleToAdd.id_organization
                );
              }}
            >
              Ajouter
            </button>
          </div>
        </div>
      </React.Fragment>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isNewArticlePopupShown: state.articles.isNewArticlePopupShown,
  chosenCategoryId: state.categories.chosenCategoryId,
  articleToAdd: state.articles.articleToAdd,
  ean_type: state.user.ean_type,
});

const mapDispatchToProps = (dispatch) => ({
  exitNewArticlePopup: () => dispatch(exitNewArticlePopup()),
  emptyLatestArticles: () => dispatch(emptyLatestArticles()),
  addNewArticleToStore: (
    article_ean,
    id_category,
    article_title,
    article_price,
    article_cover,
    article_pictures,
    id_organization
  ) =>
    dispatch(
      addNewArticleToStore(
        article_ean,
        id_category,
        article_title,
        article_price,
        article_cover,
        article_pictures,
        id_organization
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewArticle);
