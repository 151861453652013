import React from "react";
import StoreInput from "../StoreInput/index";
import { connect } from "react-redux";
import "./index.scss";

import { setStoreInputFieldValue } from "../../actions/store.actions";
import {
  searchCommandsQuery,
  emptySearchCommandsQuery,
} from "../../actions/commands.actions";

type SearchCommandsSetProps = {
  // Redux State
  storeInputFieldValue?: any;

  // Redux Actions
  setStoreInputFieldValue?: any;
  searchCommandsQuery?: any;
  emptySearchCommandsQuery?: any;
};

class SearchCommandsSet extends React.Component<SearchCommandsSetProps> {
  handleSubmit = (event) => {
    const {
      storeInputFieldValue,
      emptySearchCommandsQuery,
      searchCommandsQuery,
    } = this.props;
    event.preventDefault();
    if (storeInputFieldValue.length < 2) {
      return;
    } else {
      emptySearchCommandsQuery();
      searchCommandsQuery(storeInputFieldValue);
    }
  };

  handleCommandsSearch = (event) => {
    const { setStoreInputFieldValue } = this.props;
    setStoreInputFieldValue(event.target.value);
  };

  render() {
    const { storeInputFieldValue } = this.props;
    return (
      <form className="SearchCommandsSet" onSubmit={this.handleSubmit}>
        <StoreInput
          idStoreInput={"searchCommandsSetInput"}
          typeStoreInput={"text"}
          placeholderStoreInput={
            "Saisissez le numéro de commande ou le nom et prénom du client"
          }
          storeInputFillMethod={this.handleCommandsSearch}
        />

        <button
          id="searchCommandsSetButton"
          type="submit"
          className={
            storeInputFieldValue.length >= 2
              ? "enabledButton"
              : "disabledButton"
          }
        >
          Rechercher
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  storeInputFieldValue: state.store.storeInputFieldValue,
});

const mapDispatchToProps = (dispatch) => ({
  setStoreInputFieldValue: (input_value) =>
    dispatch(setStoreInputFieldValue(input_value)),
  searchCommandsQuery: (input_value) =>
    dispatch(searchCommandsQuery(input_value)),
  emptySearchCommandsQuery: () => dispatch(emptySearchCommandsQuery()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCommandsSet);
