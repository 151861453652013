export const TOGGLE_CONFIRM_BUTTON = "TOGGLE_CONFIRM_BUTTON";
export const RESET_CONFIRM_BUTTON = "RESET_CONFIRM_BUTTON";

export const GET_LATEST_NEW_COMMANDS_COUNT = "GET_LATEST_NEW_COMMANDS_COUNT";
export const GET_LATEST_NEW_COMMANDS_COUNT_PENDING =
  "GET_LATEST_NEW_COMMANDS_COUNT_PENDING";
export const GET_LATEST_NEW_COMMANDS_COUNT_REJECTED =
  "GET_LATEST_NEW_COMMANDS_COUNT_REJECTED";
export const GET_LATEST_NEW_COMMANDS_COUNT_FULFILLED =
  "GET_LATEST_NEW_COMMANDS_COUNT_FULFILLED";

export const GET_LATEST_NEW_COMMANDS = "GET_LATEST_NEW_COMMANDS";
export const GET_LATEST_NEW_COMMANDS_PENDING =
  "GET_LATEST_NEW_COMMANDS_PENDING";
export const GET_LATEST_NEW_COMMANDS_REJECTED =
  "GET_LATEST_NEW_COMMANDS_REJECTED";
export const GET_LATEST_NEW_COMMANDS_FULFILLED =
  "GET_LATEST_NEW_COMMANDS_FULFILLED";
export const EMPTY_LATEST_NEW_COMMANDS = "EMPTY_LATEST_NEW_COMMANDS";

export const GET_LATEST_PENDING_COMMANDS = "GET_LATEST_PENDING_COMMANDS";
export const GET_LATEST_PENDING_COMMANDS_PENDING =
  "GET_LATEST_PENDING_COMMANDS_PENDING";
export const GET_LATEST_PENDING_COMMANDS_REJECTED =
  "GET_LATEST_PENDING_COMMANDS_REJECTED";
export const GET_LATEST_PENDING_COMMANDS_FULFILLED =
  "GET_LATEST_PENDING_COMMANDS_FULFILLED";
export const EMPTY_LATEST_PENDING_COMMANDS = "EMPTY_LATEST_PENDING_COMMANDS";

export const GET_LATEST_CANCELED_COMMANDS = "GET_LATEST_CANCELED_COMMANDS";
export const GET_LATEST_CANCELED_COMMANDS_PENDING =
  "GET_LATEST_CANCELED_COMMANDS_PENDING";
export const GET_LATEST_CANCELED_COMMANDS_REJECTED =
  "GET_LATEST_CANCELED_COMMANDS_REJECTED";
export const GET_LATEST_CANCELED_COMMANDS_FULFILLED =
  "GET_LATEST_CANCELED_COMMANDS_FULFILLED";
export const EMPTY_LATEST_CANCELED_COMMANDS = "EMPTY_LATEST_CANCELED_COMMANDS";

export const GET_LATEST_HISTORY_COMMANDS = "GET_LATEST_HISTORY_COMMANDS";
export const GET_LATEST_HISTORY_COMMANDS_PENDING =
  "GET_LATEST_HISTORY_COMMANDS_PENDING";
export const GET_LATEST_HISTORY_COMMANDS_REJECTED =
  "GET_LATEST_HISTORY_COMMANDS_REJECTED";
export const GET_LATEST_HISTORY_COMMANDS_FULFILLED =
  "GET_LATEST_HISTORY_COMMANDS_FULFILLED";
export const EMPTY_LATEST_HISTORY_COMMANDS = "EMPTY_LATEST_HISTORY_COMMANDS";

export const GET_FOCUSED_COMMAND = "GET_FOCUSED_COMMAND";
export const GET_FOCUSED_COMMAND_PENDING = "GET_FOCUSED_COMMAND_PENDING";
export const GET_FOCUSED_COMMAND_REJECTED = "GET_FOCUSED_COMMAND_REJECTED";
export const GET_FOCUSED_COMMAND_FULFILLED = "GET_FOCUSED_COMMAND_FULFILLED";
export const EMPTY_FOCUSED_COMMAND = "EMPTY_FOCUSED_COMMAND";

export const PATCH_FOCUSED_COMMAND_TO_PENDING =
  "PATCH_FOCUSED_COMMAND_TO_PENDING";
export const PATCH_FOCUSED_COMMAND_TO_PENDING_PENDING =
  "PATCH_FOCUSED_COMMAND_TO_PENDING_PENDING";
export const PATCH_FOCUSED_COMMAND_TO_PENDING_REJECTED =
  "PATCH_FOCUSED_COMMAND_TO_PENDING_REJECTED";
export const PATCH_FOCUSED_COMMAND_TO_PENDING_FULFILLED =
  "PATCH_FOCUSED_COMMAND_TO_PENDING_FULFILLED";

export const PATCH_FOCUSED_COMMAND_TO_CANCELED =
  "PATCH_FOCUSED_COMMAND_TO_CANCELED";
export const PATCH_FOCUSED_COMMAND_TO_CANCELED_PENDING =
  "PATCH_FOCUSED_COMMAND_TO_CANCELED_PENDING";
export const PATCH_FOCUSED_COMMAND_TO_CANCELED_REJECTED =
  "PATCH_FOCUSED_COMMAND_TO_CANCELED_REJECTED";
export const PATCH_FOCUSED_COMMAND_TO_CANCELED_FULFILLED =
  "PATCH_FOCUSED_COMMAND_TO_CANCELED_FULFILLED";

export const PATCH_FOCUSED_COMMAND_TO_HISTORY =
  "PATCH_FOCUSED_COMMAND_TO_HISTORY";
export const PATCH_FOCUSED_COMMAND_TO_HISTORY_PENDING =
  "PATCH_FOCUSED_COMMAND_TO_HISTORY_PENDING";
export const PATCH_FOCUSED_COMMAND_TO_HISTORY_REJECTED =
  "PATCH_FOCUSED_COMMAND_TO_HISTORY_REJECTED";
export const PATCH_FOCUSED_COMMAND_TO_HISTORY_FULFILLED =
  "PATCH_FOCUSED_COMMAND_TO_HISTORY_FULFILLED";

export const SEARCH_COMMANDS_QUERY = "SEARCH_COMMANDS_QUERY";
export const SEARCH_COMMANDS_QUERY_PENDING = "SEARCH_COMMANDS_QUERY_PENDING";
export const SEARCH_COMMANDS_QUERY_REJECTED = "SEARCH_COMMANDS_QUERY_REJECTED";
export const SEARCH_COMMANDS_QUERY_FULFILLED =
  "SEARCH_COMMANDS_QUERY_FULFILLED";
export const EMPTY_SEARCH_COMMANDS_QUERY = "EMPTY_SEARCH_COMMANDS_QUERY";

export const COMMANDS_LOADING = "COMMANDS_LOADING";
export const COMMANDS_NULL = "COMMANDS_NULL";
