import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";

type MenuProps = {
  // Redux State
  isLogged?: boolean;
  storeInfos?: any;
  latestNewCommandsCount?: any;
};

class Menu extends React.Component<MenuProps> {
  // Component render
  render() {
    const { isLogged, storeInfos, latestNewCommandsCount } = this.props;

    return (
      <div className="Menu ">
        <div className="menuContainer center">
          <div className="logo">
            <img src="/img/unsold_logo_black.png" alt="Logo Unsold Noir" />
            <div className="statusContainer">
              {isLogged ? (
                <React.Fragment>
                  <p className="store">
                    {storeInfos.organization_name === null
                      ? null
                      : storeInfos.organization_name}
                  </p>
                  <p className="localisation">
                    {storeInfos.store_name === null
                      ? null
                      : storeInfos.store_name}
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>Bienvenue</p>
                </React.Fragment>
              )}
            </div>
          </div>

          {isLogged ? (
            <React.Fragment>
              <nav className="loggedNav">
                <ul>
                  <li title="Nouvelles commandes">
                    <NavLink to="/commands_new" activeClassName="activeTab">
                      {latestNewCommandsCount === "0" ? null : (
                        <span className="commandBubble">
                          {latestNewCommandsCount}
                        </span>
                      )}
                      <div id="commandsNewTab" className="icon"></div>
                      <p>Nouvelles</p>
                    </NavLink>
                  </li>

                  <li title="Commandes en attentes">
                    <NavLink to="/commands_pending" activeClassName="activeTab">
                      <div id="commandsPendingTab" className="icon"></div>
                      <p>En attente</p>
                    </NavLink>
                  </li>

                  <li title="Commandes annulées">
                    <NavLink
                      to="/commands_canceled"
                      activeClassName="activeTab"
                    >
                      <div id="commandsCanceledTab" className="icon"></div>
                      <p>Annulées</p>
                    </NavLink>
                  </li>

                  <li title="Historique des commandes">
                    <NavLink to="/commands_history" activeClassName="activeTab">
                      <div id="commandsHistoryTab" className="icon"></div>
                      <p>Historique</p>
                    </NavLink>
                  </li>

                  <li title="Store">
                    <NavLink to="/store" activeClassName="activeTab">
                      <div id="storeTab" className="icon"></div>
                      <p>Store</p>
                    </NavLink>
                  </li>

                  <li title="Profil utilisateur">
                    <NavLink to="/user" activeClassName="activeTab">
                      <div id="userTab" className="icon"></div>
                      <p>Profil</p>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <nav className="loginNav">
                <NavLink to="/" activeClassName="activeTab">
                  <img
                    src="/img/icon_user_active.png"
                    alt="Icône Utilisateur"
                  />
                </NavLink>
              </nav>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogged: state.user.isLogged,
  storeInfos: state.user,
  latestNewCommandsCount: state.commands.latestNewCommandsCount,
});

export default connect(mapStateToProps)(Menu);
