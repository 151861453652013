import React from "react";
import CommandLine from "../CommandLine/index";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "./index.scss";

type CommandsListProps = {
  // Component Props
  commandsData?: any;
  isInfiniteScroll?: any;
  infiniteScrollRefresh?: any;

  // Redux State
  hasMoreCommands?: any;
};

class CommandsList extends React.Component<CommandsListProps> {
  render() {
    const {
      commandsData,
      infiniteScrollRefresh,
      hasMoreCommands,
      isInfiniteScroll,
    } = this.props;

    return (
      <ul className="CommandsList">
        {isInfiniteScroll ? (
          <InfiniteScroll
            dataLength={commandsData.length}
            next={() => infiniteScrollRefresh()}
            hasMore={hasMoreCommands}
            loader={
              <React.Fragment>
                <Skeleton className="commandLineSkeleton" count={9} />
              </React.Fragment>
            }
            endMessage={<hr />}
          >
            {commandsData.map((command, key) => {
              return (
                <CommandLine
                  key={key}
                  commandData={command}
                  commandClassName="CommandLine"
                />
              );
            })}
          </InfiniteScroll>
        ) : (
          commandsData.map((command, key) => {
            return (
              <CommandLine
                key={key}
                commandData={command}
                commandClassName="CommandLine"
              />
            );
          })
        )}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  hasMoreCommands: state.commands.hasMoreCommands,
});

export default connect(mapStateToProps)(CommandsList);
