import React from "react";
import Article from "../Article/index";
import { connect } from "react-redux";
import "./index.scss";

import {
  hideDeleteArticleFromCommandPopup,
  deleteArticleFromCommand,
} from "../../actions/articles.actions";

type DeleteArticleFromCommandProps = {
  //Props
  articleToDeleteFromCommand?: any;

  // Redux State
  isDeleteArticleFromCommandPopupShown?: any;

  // Redux Actions
  hideDeleteArticleFromCommandPopup?: any;
  deleteArticleFromCommand?: any;
};

class DeleteArticleFromCommand extends React.Component<
  DeleteArticleFromCommandProps
> {
  // Component render
  render() {
    const {
      articleToDeleteFromCommand,
      hideDeleteArticleFromCommandPopup,
      deleteArticleFromCommand,
      isDeleteArticleFromCommandPopupShown,
    } = this.props;

    return isDeleteArticleFromCommandPopupShown ? (
      <div className="DeleteArticleFromCommand">
        {isDeleteArticleFromCommandPopupShown
          ? document.body.classList.add("noScroll")
          : null}
        <div className="deleteArticleFromCommandContainer center">
          <div className="deleteArticleFromCommandBanner">
            <p className="deleteArticleFromCommandText">
              <strong>Supprimer cet article de la commande ?</strong>
              <img
                src="/img/close_cross_black.png"
                alt="Croix fermeture popup"
                onClick={() => hideDeleteArticleFromCommandPopup()}
              />
            </p>
            <p className="deleteArticleFromCommandConfirmText">
              Confirmez-vous vouloir supprimer cet article de la commande ?
            </p>
            <ul className="deletArticleFromCommandOverview">
              <Article dataArticle={articleToDeleteFromCommand[0]} />
            </ul>
            <ul className="deletArticleFromCommandStatus">
              <li>
                <button
                  className="deletArticleFromCommandButton"
                  onClick={() => hideDeleteArticleFromCommandPopup()}
                >
                  Annuler
                </button>
              </li>
              <li>
                <button
                  className="deletArticleFromCommandButton"
                  onClick={() =>
                    deleteArticleFromCommand(
                      articleToDeleteFromCommand[0].id_rel_orders_products
                    )
                  }
                >
                  Supprimer
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isDeleteArticleFromCommandPopupShown:
    state.articles.isDeleteArticleFromCommandPopupShown,
  articleToDeleteFromCommand: state.articles.articleToDeleteFromCommand,
});

const mapDispatchToProps = (dispatch) => ({
  hideDeleteArticleFromCommandPopup: () =>
    dispatch(hideDeleteArticleFromCommandPopup()),
  deleteArticleFromCommand: (id_rel_orders_products) =>
    dispatch(deleteArticleFromCommand(id_rel_orders_products)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteArticleFromCommand);
