import { getAllCategoriesFromDB } from "../api/categories.api";
import * as categoriesConst from "../const/categories.const";

export const getAllCategories = () => ({
  type: categoriesConst.GET_ALL_CATEGORIES,
  payload: getAllCategoriesFromDB(),
});

export const handleCategoriesBackButton = (id_category) => ({
  type: categoriesConst.HANDLE_CATEGORIES_BACK_BUTTON,
  payload: id_category,
});

export const handleCategoriesNavigation = (id_category) => ({
  type: categoriesConst.HANDLE_CATEGORIES_NAVIGATION,
  payload: id_category,
});

export const handleCategoriesSelection = (id_category, category_name) => ({
  type: categoriesConst.HANDLE_CATEGORIES_SELECTION,
  payload: {
    id_category: id_category,
    category_name: category_name,
  },
});

export const handleCategoriesReset = () => ({
  type: categoriesConst.HANDLE_CATEGORIES_RESET,
});
