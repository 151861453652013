import React from "react";
import UnknownArticle from "../../components/UnknownArticle/index";
import PageHeader from "../../components/PageHeader/index";
import ScanSearchTab from "../../components/ScanSearchTab/index";
import NewArticle from "../../components/NewArticle/index";
import StoreAddOverview from "../../components/StoreAddOverview/index";
import StoreSearchOverview from "../../components/StoreSearchOverview/index";
import StoreCommandsOverview from "../../components/StoreCommandsOverview/index";
import { connect } from "react-redux";
import "./index.scss";

import { storeInit } from "../../actions/store.actions";

type StoreProps = {
  // Redux State
  activeStoreTab?: number;
  isNewArticlePopupShown?: boolean;

  // Redux actions
  storeInit?: any;
};

class Store extends React.Component<StoreProps> {
  componentDidMount() {
    const { storeInit } = this.props;
    storeInit();
  }
  // Component render
  render() {
    const { activeStoreTab, isNewArticlePopupShown } = this.props;

    return (
      <React.Fragment>
        <UnknownArticle />
        <div className="Store center">
          <PageHeader title="Dasboard magasin" />
          <ScanSearchTab />
          {isNewArticlePopupShown === true && activeStoreTab === 1 ? (
            <NewArticle />
          ) : null}
          <p className="lastAddedArticles">
            {activeStoreTab === 1
              ? "Derniers articles ajoutés"
              : "Résultat de la recherche"}
          </p>
          {activeStoreTab === 1 ? (
            <StoreAddOverview />
          ) : activeStoreTab === 2 ? (
            <StoreSearchOverview />
          ) : activeStoreTab === 3 ? (
            <StoreCommandsOverview />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStoreTab: state.menus.activeStoreTab,
  isNewArticlePopupShown: state.articles.isNewArticlePopupShown,
});

const mapDispatchToProps = (dispatch) => ({
  storeInit: () => dispatch(storeInit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Store);
