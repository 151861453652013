import * as toastConst from "../const/toasts.const";

const initState = {};

const toasts = (state = initState, action) => {
  switch (action.type) {
    case toastConst.TOASTIFY_NEW_COMMAND:
      return { ...state };
    default:
      return state;
  }
};

export default toasts;
