import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

type BackButtonProps = {
  // Props
  backButtonUrl?: string;
};

class BackButton extends React.Component<BackButtonProps> {
  // Component render
  render() {
    const { backButtonUrl } = this.props;

    return (
      <Link to={`${backButtonUrl}`} className="BackButton">
        <img src="/img/back_button_action_arrow.png" alt="Flêche Retour" />
      </Link>
    );
  }
}

export default BackButton;
