import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { logout } from "../../actions/user.actions";

type UserProps = {
  // Redux state
  user?: any;

  // Redux actions
  logout?: any;
};

class User extends React.Component<UserProps> {
  render() {
    const { user, logout } = this.props;

    return (
      <div className="User center">
        <h4>
          {user.organization_name} - {user.store_name}
        </h4>

        <h5>Organisation :</h5>
        <hr />
        <div className="userInfos">
          <div className="userDiv">
            <ul>
              <li>
                <strong>ID organisation</strong>
                <p>{user.id_organization}</p>
              </li>
              <li>
                <strong>Type d'EAN </strong>
                <p>{user.ean_type}</p>
              </li>
            </ul>
          </div>
          <div className="userDiv">
            <ul>
              <li>
                <strong>Nom organisation </strong>
                <p>{user.organization_name}</p>
              </li>
            </ul>
          </div>
        </div>

        <h5>Magasin :</h5>
        <hr />
        <div className="userInfos">
          <div className="userDiv">
            <ul>
              <li>
                <strong>ID magasin</strong>
                <p>{user.id_store}</p>
              </li>
              <li>
                <strong>Nom magasin </strong>
                <p>{user.store_name}</p>
              </li>
              <li>
                <strong>Téléphone magasin </strong>
                <p>{user.store_phone}</p>
              </li>
            </ul>
          </div>
          <div className="userDiv">
            <ul>
              <li>
                <strong>Adresse magasin </strong>
                <p>{user.store_adress}</p>
              </li>
              <li>
                <strong>Latitude magasin</strong>
                <p>{user.store_latitude}</p>
              </li>
              <li>
                <strong>Longitude magasin</strong>
                <p>{user.store_longitude}</p>
              </li>
            </ul>
          </div>
        </div>

        <h5>Utilisateur :</h5>
        <hr />
        <div className="userInfos">
          <div className="userDiv">
            <ul>
              <li>
                <strong>ID utilisateur</strong>
                <p>{user.id_users_dashboard}</p>
              </li>
            </ul>
          </div>
          <div className="userDiv">
            <ul>
              <li>
                <strong>Nom utilisateur</strong>
                <p>{user.username}</p>
              </li>
            </ul>
          </div>
        </div>

        <h5>Ville :</h5>
        <hr />
        <div className="userInfos">
          <div className="userDiv">
            <ul>
              <li>
                <strong>ID ville</strong>
                <p>{user.id_city}</p>
              </li>
              <li>
                <strong>Code postal ville</strong>
                <p>{user.city_postal}</p>
              </li>
            </ul>
          </div>
          <div className="userDiv">
            <ul>
              <li>
                <strong>Ville</strong>
                <p>{user.city_name}</p>
              </li>
              <li>
                <strong>Département</strong>
                <p>{user.city_department}</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="disconnectButton">
          <button onClick={() => logout(user.id_store)}>Se déconnecter</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (id_store) => dispatch(logout(id_store)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
