import React from "react";
import Skeleton from "react-loading-skeleton";
import "./index.scss";

const ArticleSkeleton = () => {
  return (
    <li className="ArticleSkeleton">
      <div className="articlePhotoSkeleton">
        <Skeleton className="photoSkeleton" />
      </div>

      <div className="articleDescriptionSkeleton">
        <ul>
          <li>
            <p>Nom de l'article</p>
            <Skeleton />
          </li>
          <li>
            <p>Quantité</p>
            <Skeleton />
          </li>
          <li>
            <p>Prix unitaire</p>
            <Skeleton />
          </li>
        </ul>
        <ul>
          <li>
            <p>Choix de la taille</p>
            <Skeleton className="sizeSkeleton" />
          </li>
        </ul>
        <ul>
          <li>
            <p>EAN Type</p>
            <Skeleton />
          </li>
        </ul>
      </div>
    </li>
  );
};

export default ArticleSkeleton;
