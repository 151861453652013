import React from "react";
import StoreInput from "../StoreInput/index";
import { connect } from "react-redux";
import "./index.scss";

import { setStoreInputFieldValue } from "../../actions/store.actions";
import {
  handleInputEan,
  emptyLatestArticles,
} from "../../actions/articles.actions";

type ScanSetProps = {
  // Redux State
  storeInputFieldValue?: any;
  ean_length?: number;

  // Redux Actions
  setStoreInputFieldValue?: any;
  emptyLatestArticles?: any;
  handleInputEan?: any;
};

class ScanSet extends React.Component<ScanSetProps> {
  handleSubmit = (event) => {
    event.preventDefault();
    const { storeInputFieldValue, handleInputEan, ean_length } = this.props;

    if (storeInputFieldValue.length === ean_length) {
      handleInputEan();
    } else if (ean_length === undefined) {
      handleInputEan();
    } else {
      return false;
    }
  };

  handleScanSet = (event) => {
    const { setStoreInputFieldValue } = this.props;
    setStoreInputFieldValue(event.target.value);
  };

  // Component render
  render() {
    const { storeInputFieldValue, ean_length } = this.props;
    return (
      <form className={"ScanSet"} onSubmit={this.handleSubmit}>
        <StoreInput
          idStoreInput={"scanSetInput"}
          typeStoreInput={"text"}
          placeholderStoreInput={
            "Scannez le code barre ou saisissez le code EAN"
          }
          storeInputFillMethod={this.handleScanSet}
          maxLength={ean_length}
        />

        {ean_length === undefined ? (
          <button type="submit" id="scanSetButton" className={"enabledButton"}>
            Ajouter
          </button>
        ) : (
          <button
            type="submit"
            id="scanSetButton"
            className={
              storeInputFieldValue.length === ean_length
                ? "enabledButton"
                : "disabledButton"
            }
          >
            Ajouter
          </button>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  storeInputFieldValue: state.store.storeInputFieldValue,
  ean_length: state.user.ean_length,
});

const mapDispatchToProps = (dispatch) => ({
  setStoreInputFieldValue: (input_value) =>
    dispatch(setStoreInputFieldValue(input_value)),
  handleInputEan: () => dispatch(handleInputEan()),
  emptyLatestArticles: () => dispatch(emptyLatestArticles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanSet);
