import * as menusConst from "../const/menus.const";
import * as userConst from "../const/user.const";

const initState = {
  isManageMenuOpen: false,
  isCategoriesMenuOpen: false,
  activeStoreTab: 1,
};

const menus = (state = initState, action) => {
  switch (action.type) {
    case menusConst.TOGGLE_ACTIVE_STORE_TAB:
      return { ...state, activeStoreTab: action.payload };

    case menusConst.RESET_ACTIVE_STORE_TAB:
      return { ...state, activeStoreTab: 1 };

    case menusConst.OPEN_MANAGE_MENU:
      return { ...state, isManageMenuOpen: true };

    case menusConst.CLOSE_MANAGE_MENU:
      return { ...state, isManageMenuOpen: false };

    case menusConst.TOGGLE_CATEGORIES_MENU:
      return { ...state, isCategoriesMenuOpen: !state.isCategoriesMenuOpen };

    case menusConst.CLOSE_CATEGORIES_MENU:
      return { ...state, isCategoriesMenuOpen: false };

    case userConst.FETCH_LOGOUT_FULFILLED:
      return { ...initState };

    default:
      return state;
  }
};

export default menus;
