import * as storeConst from "../const/store.const";

export const setStoreInputFieldValue = (input_value) => ({
  type: storeConst.SET_STORE_INPUT_FIELD_VALUE,
  payload: input_value,
});

export const emptyStoreInputFieldValue = () => ({
  type: storeConst.EMPTY_STORE_INPUT_FIELD_VALUE,
});

export const storeInit = () => ({
  type: storeConst.STORE_INIT,
});
