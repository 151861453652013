import axios from "axios";

export const getLatestNewCommandsCountFromDB = () =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/commands/new/count`);

export const getLatestNewCommandsFromDB = (latestNewCommandsPage) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/commands/new/latest`, {
    latestNewCommandsPage,
  });

export const getLatestPendingCommandsFromDB = (latestPendingCommandsPage) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/commands/pending/latest`, {
    latestPendingCommandsPage,
  });

export const getLatestCanceledCommandsFromDB = (latestCanceledCommandsPage) =>
  axios.post(
    `${process.env.REACT_APP_API_URL}/admin/commands/canceled/latest`,
    {
      latestCanceledCommandsPage,
    }
  );

export const getLatestHistoryCommandsFromDB = (latestHistoryCommandsPage) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/commands/history/latest`, {
    latestHistoryCommandsPage,
  });

export const getFocusedCommandFromDB = (id_order) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/commands/focused`, {
    id_order,
  });

export const patchFocusedCommandToPendingFromDB = (id_order) =>
  axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/commands/focused/to_pending`,
    {
      id_order,
    }
  );

export const patchFocusedCommandToCanceledFromDB = (id_order) =>
  axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/commands/focused/to_canceled`,
    {
      id_order,
    }
  );

export const patchFocusedCommandToHistoryFromDB = (id_order) =>
  axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/commands/focused/to_history`,
    {
      id_order,
    }
  );

export const searchCommandsQueryFromDB = (input_value) =>
  axios.post(`${process.env.REACT_APP_API_URL}/admin/commands/search`, {
    input_value,
  });
