import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { persistStore, persistReducer } from "redux-persist";
//import localForage from "localforage";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import rootSaga from "./sagas/index";

export const history = createBrowserHistory();

export const sagaMiddleware = createSagaMiddleware();
const middlewares = [promise, thunk, routerMiddleware(history), sagaMiddleware];

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

export const store = createStore(
  persistReducer(persistConfig, reducers),
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
