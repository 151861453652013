import React from "react";
import PageHeader from "../../components/PageHeader/index";
import CommandsLabels from "../../components/CommandsLabels/index";
import CommandsList from "../../components/CommandsList/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "./index.scss";

import {
  getLatestNewCommands,
  emptyLatestNewCommands,
  commandsLoading,
  commandsNull,
} from "../../actions/commands.actions";

type CommandsNewProps = RouteComponentProps & {
  // Redux State
  latestNewCommands?: any;
  latestNewCommandsPage?: any;
  commandsAreLoading?: any;

  // Redux Actions
  getLatestNewCommands?: any;
  emptyLatestNewCommands?: any;
  commandsLoading?: any;
  commandsNull?: any;
};

class CommandsNew extends React.Component<CommandsNewProps> {
  async componentDidMount() {
    const {
      getLatestNewCommands,
      emptyLatestNewCommands,
      commandsLoading,
    } = this.props;
    await commandsLoading();
    await emptyLatestNewCommands();
    await getLatestNewCommands();
  }

  async componentWillUnmount() {
    const { emptyLatestNewCommands, commandsNull } = this.props;
    await commandsNull();
    await emptyLatestNewCommands();
  }

  render() {
    const {
      getLatestNewCommands,
      latestNewCommands,
      latestNewCommandsPage,
      commandsAreLoading,
    } = this.props;

    return (
      <div className="CommandsNew center">
        <PageHeader title="Nouvelles commandes" />
        <CommandsLabels />
        {commandsAreLoading === false && latestNewCommands.length === 0 ? (
          <div className="emptyNewCommands">
            <img
              src="/img/empty_commands_icon.png"
              alt="Icone pas de nouvelles commandes"
            />
            <strong>Aucune nouvelle commande</strong>
            <p>Les nouvelles commandes aparaitront ici.</p>
          </div>
        ) : (
          <CommandsList
            commandsData={latestNewCommands}
            isInfiniteScroll
            infiniteScrollRefresh={() =>
              getLatestNewCommands(latestNewCommandsPage)
            }
          />
        )}
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  latestNewCommands: state.commands.latestNewCommands,
  latestNewCommandsPage: state.commands.latestNewCommandsPage,
  commandsAreLoading: state.commands.commandsAreLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getLatestNewCommands: (latestNewCommandsPage) =>
    dispatch(getLatestNewCommands(latestNewCommandsPage)),
  emptyLatestNewCommands: () => dispatch(emptyLatestNewCommands()),
  commandsLoading: () => dispatch(commandsLoading()),
  commandsNull: () => dispatch(commandsNull()),
});

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(CommandsNew)
);
