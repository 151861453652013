import * as articlesConst from "../const/articles.const";
import * as userConst from "../const/user.const";

const initState = {
  hasMoreArticles: false,
  latestArticles: [],
  latestArticlesPage: 1,
  searchArticles: [],
  searchArticlesPage: 1,
  searchArticlesParams: {
    searchArticlesChosenCategoryId: null,
    searchArticlesTitle: "",
  },
  articleToAdd: {},
  articleToDelete: [],
  articleToDeleteFromCommand: [],
  isNewArticlePopupShown: false,
  isUnknownArticlePopupShown: false,
  isDeleteArticlePopupShown: false,
  isDeleteArticleFromCommandPopupShown: false,
};

const articles = (state = initState, action) => {
  switch (action.type) {
    case articlesConst.GET_LATEST_ARTICLES_PENDING:
      return { ...state, hasMoreArticles: true };

    case articlesConst.GET_LATEST_ARTICLES_FULFILLED:
      if (action.payload.data.length === 0) {
        return { ...state, hasMoreArticles: false };
      } else {
        return {
          ...state,
          hasMoreArticles: true,
          latestArticles: state.latestArticles.concat(action.payload.data),
          latestArticlesPage: state.latestArticlesPage + 1,
        };
      }

    case articlesConst.EMPTY_LATEST_ARTICLES:
      return {
        ...state,
        latestArticles: [],
        latestArticlesPage: 1,
      };

    case articlesConst.SHOW_UNKNOWN_ARTICLE_POPUP:
      return { ...state, isUnknownArticlePopupShown: true };

    case articlesConst.HIDE_UNKNOWN_ARTICLE_POPUP:
      return { ...state, isUnknownArticlePopupShown: false };

    case articlesConst.SHOW_NEW_ARTICLE_POPUP:
      return { ...state, isNewArticlePopupShown: true };

    case articlesConst.HIDE_NEW_ARTICLE_POPUP:
      return { ...state, isNewArticlePopupShown: false };

    case articlesConst.SHOW_DELETE_ARTICLE_POPUP:
      return {
        ...state,
        isDeleteArticlePopupShown: true,
        articleToDelete: [action.payload],
      };

    case articlesConst.HIDE_DELETE_ARTICLE_POPUP:
      return {
        ...state,
        isDeleteArticlePopupShown: false,
        articleToDelete: [],
      };

    case articlesConst.SHOW_DELETE_ARTICLE_FROM_COMMAND_POPUP:
      return {
        ...state,
        isDeleteArticleFromCommandPopupShown: true,
        articleToDeleteFromCommand: [action.payload],
      };

    case articlesConst.HIDE_DELETE_ARTICLE_FROM_COMMAND_POPUP:
      return {
        ...state,
        isDeleteArticleFromCommandPopupShown: false,
        articleToDeleteFromCommand: [],
      };

    case articlesConst.EXIT_NEW_ARTICLE_POPUP:
      return { ...state, isNewArticlePopupShown: false };

    case articlesConst.FETCH_NEW_ARTICLE_INFOS_IN_REDUCER:
      return { ...state, articleToAdd: action.payload };

    case articlesConst.EMPTY_NEW_ARTICLE_INFOS_IN_REDUCER:
      return { ...state, articleToAdd: {} };

    case articlesConst.SEARCH_ARTICLES_QUERY_PENDING:
      return { ...state, hasMoreArticles: true };

    case articlesConst.SEARCH_ARTICLES_QUERY_FULFILLED:
      if (action.payload.data.searchResults.length === 0) {
        return { ...state, hasMoreArticles: false };
      } else {
        return {
          ...state,
          hasMoreArticles: true,
          searchArticles: state.searchArticles.concat(
            action.payload.data.searchResults
          ),
          searchArticlesPage: state.searchArticlesPage + 1,
          searchArticlesParams: {
            searchArticlesChosenCategoryId:
              action.payload.data.searchArticlesChosenCategoryId,
            searchArticlesTitle: action.payload.data.searchArticlesTitle,
          },
        };
      }

    case articlesConst.EMPTY_SEARCH_ARTICLES_QUERY:
      return {
        ...state,
        searchArticles: [],
        searchArticlesPage: 1,
        searchArticlesParams: {
          searchArticlesChosenCategoryId: null,
          searchArticlesTitle: "",
        },
      };

    case userConst.FETCH_LOGOUT_FULFILLED:
      return { ...initState };

    default:
      return state;
  }
};

export default articles;
