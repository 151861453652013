import { put } from "redux-saga/effects";
import { handleCategoriesReset } from "../actions/categories.actions";
import { emptyStoreInputFieldValue } from "../actions/store.actions";
import {
  hideNewArticlePopup,
  emptyNewArticleInfosInReducer,
  hideUnknownArticlePopup,
  hideDeleteArticlePopup,
  emptySearchArticlesQuery,
} from "../actions/articles.actions";

export function* toggleActiveStoreTabLogic() {
  // ---------- Store Input ----------
  // Empty store Input
  yield put(emptyStoreInputFieldValue());

  // ---------- Categories ----------
  // Reset chosenCategoryID/Name and activeCategoryId to init values
  yield put(handleCategoriesReset());

  // ---------- Popups ----------
  // ---------- Article to Add ----------
  // ---------- Article to Delete ----------
  // Hide DeleteArticle Popup and empty articletoDelete in reducer
  yield put(hideDeleteArticlePopup());
  // Hide  NewArticle Popup
  yield put(hideNewArticlePopup());
  // Empty New Article in reducer
  yield put(emptyNewArticleInfosInReducer());
  // Hide  UnknownArticle Popup
  yield put(hideUnknownArticlePopup());

  // ---------- Article List ----------
  // yield put(emptyLatestArticles());

  // ---------- Search List ----------
  yield put(emptySearchArticlesQuery());
}
