import React from "react";
import PageHeader from "../../components/PageHeader/index";
import CommandsLabels from "../../components/CommandsLabels/index";
import CommandsList from "../../components/CommandsList/index";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import "./index.scss";

import {
  getLatestCanceledCommands,
  emptyLatestCanceledCommands,
  commandsLoading,
  commandsNull,
} from "../../actions/commands.actions";

type CommandsCanceledProps = RouteComponentProps & {
  // Redux State
  latestCanceledCommands?: any;
  latestCanceledCommandsPage?: any;
  commandsAreLoading?: any;

  // Redux Actions
  getLatestCanceledCommands?: any;
  emptyLatestCanceledCommands?: any;
  commandsLoading?: any;
  commandsNull?: any;
};

class CommandsCanceled extends React.Component<CommandsCanceledProps> {
  async componentDidMount() {
    const {
      getLatestCanceledCommands,
      emptyLatestCanceledCommands,
      commandsLoading,
    } = this.props;
    await commandsLoading();
    await emptyLatestCanceledCommands();
    await getLatestCanceledCommands();
  }

  async componentWillUnmount() {
    const { emptyLatestCanceledCommands, commandsNull } = this.props;
    await commandsNull();
    await emptyLatestCanceledCommands();
  }

  render() {
    const {
      getLatestCanceledCommands,
      latestCanceledCommands,
      latestCanceledCommandsPage,
      commandsAreLoading,
    } = this.props;
    return (
      <div className="CommandsCanceled center">
        <PageHeader title="Commandes annulées" />
        <CommandsLabels />
        {commandsAreLoading === false && latestCanceledCommands.length === 0 ? (
          <div className="emptyCanceledCommands">
            <img
              src="/img/empty_commands_icon.png"
              alt="Icone pas de commandes annulées"
            />
            <strong>Aucune commande annulée</strong>
            <p>Les commandes annulées aparaitront ici.</p>
          </div>
        ) : (
          <CommandsList
            commandsData={latestCanceledCommands}
            isInfiniteScroll
            infiniteScrollRefresh={() =>
              getLatestCanceledCommands(latestCanceledCommandsPage)
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  latestCanceledCommands: state.commands.latestCanceledCommands,
  latestCanceledCommandsPage: state.commands.latestCanceledCommandsPage,
  commandsAreLoading: state.commands.commandsAreLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getLatestCanceledCommands: (latestCanceledCommandsPage) =>
    dispatch(getLatestCanceledCommands(latestCanceledCommandsPage)),
  emptyLatestCanceledCommands: () => dispatch(emptyLatestCanceledCommands()),
  commandsLoading: () => dispatch(commandsLoading()),
  commandsNull: () => dispatch(commandsNull()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommandsCanceled)
);
