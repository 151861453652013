import React from "react";
import ArticlesList from "../ArticlesList/index";
import { connect } from "react-redux";
import "./index.scss";

import {
  getLatestArticles,
  emptyLatestArticles,
} from "../../actions/articles.actions";

type StoreAddOverviewProps = {
  // Redux State
  latestArticles?: any;

  // Redux Action
  getLatestArticles?: any;
  emptyLatestArticles?: any;
};

class StoreAddOverview extends React.Component<StoreAddOverviewProps> {
  async componentDidMount() {
    const { getLatestArticles, emptyLatestArticles } = this.props;
    await emptyLatestArticles();
    await getLatestArticles();
  }

  componentWillUnmount() {
    const { emptyLatestArticles } = this.props;
    emptyLatestArticles();
  }

  render() {
    const { latestArticles } = this.props;

    return (
      <div className="StoreAddOverview">
        <ArticlesList
          dataArticlesList={latestArticles}
          canBeDeleted
          isInfiniteScroll
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  latestArticles: state.articles.latestArticles,
});

const mapsDispatchToProps = (dispatch) => ({
  getLatestArticles: () => dispatch(getLatestArticles()),
  emptyLatestArticles: () => dispatch(emptyLatestArticles()),
});

export default connect(mapStateToProps, mapsDispatchToProps)(StoreAddOverview);
