import React from "react";
import Article from "../Article/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ArticleSkeleton from "../ArticleSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.scss";

import {
  getLatestArticles,
  searchArticlesQuery,
} from "../../actions/articles.actions";

type ArticlesListProps = RouteComponentProps & {
  // Component Props
  dataArticlesList?: any;
  canBeDeleted?: boolean;
  canBeDeletedFromCommand?: boolean;
  isInfiniteScroll?: boolean;

  // Redux State
  activeStoreTab?: number;
  latestArticlesPage?: number;
  searchArticlesPage?: number;
  searchArticlesChosenCategoryId?: number;
  searchArticlesTitle?: string;
  isFocusedCommandsArticlesListLoading?: any;
  hasMoreArticles?: any;

  // Redux Actions
  getLatestArticles?: any;
  searchArticlesQuery?: any;
};

class ArticlesList extends React.Component<ArticlesListProps> {
  // Component render
  render() {
    const {
      canBeDeleted,
      canBeDeletedFromCommand,
      isInfiniteScroll,
      dataArticlesList,
      activeStoreTab,
      latestArticlesPage,
      searchArticlesPage,
      searchArticlesChosenCategoryId,
      searchArticlesTitle,
      isFocusedCommandsArticlesListLoading,
      hasMoreArticles,
      getLatestArticles,
      searchArticlesQuery,
    } = this.props;

    return (
      <ul className="ArticlesList">
        {isInfiniteScroll ? (
          <InfiniteScroll
            dataLength={dataArticlesList.length}
            next={() => {
              activeStoreTab === 1
                ? getLatestArticles(latestArticlesPage)
                : searchArticlesQuery(
                    searchArticlesChosenCategoryId,
                    searchArticlesTitle,
                    searchArticlesPage
                  );
            }}
            hasMore={hasMoreArticles}
            loader={<ArticleSkeleton />}
            endMessage={<hr />}
          >
            {dataArticlesList.map((article, key) => {
              return (
                <Article
                  key={key}
                  dataArticle={article}
                  canBeDeleted={canBeDeleted}
                />
              );
            })}
          </InfiniteScroll>
        ) : isFocusedCommandsArticlesListLoading === true ? (
          <ArticleSkeleton />
        ) : canBeDeletedFromCommand ? (
          dataArticlesList.map((article, key) => {
            return (
              <Article
                key={key}
                dataArticle={article}
                canBeDeletedFromCommand={canBeDeletedFromCommand}
              />
            );
          })
        ) : (
          dataArticlesList.map((article, key) => {
            return (
              <Article
                key={key}
                dataArticle={article}
                canBeDeleted={canBeDeleted}
              />
            );
          })
        )}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  activeStoreTab: state.menus.activeStoreTab,
  latestArticlesPage: state.articles.latestArticlesPage,
  searchArticlesPage: state.articles.searchArticlesPage,
  searchArticlesChosenCategoryId:
    state.articles.searchArticlesParams.searchArticlesChosenCategoryId,
  searchArticlesTitle: state.articles.searchArticlesParams.searchArticlesTitle,
  isFocusedCommandsArticlesListLoading:
    state.commands.isFocusedCommandsArticlesListLoading,
  hasMoreArticles: state.articles.hasMoreArticles,
});

const mapDispatchToProps = (dispatch) => ({
  getLatestArticles: (latestArticlesPage) =>
    dispatch(getLatestArticles(latestArticlesPage)),
  searchArticlesQuery: (id_category, title, searchArticlePage) =>
    dispatch(searchArticlesQuery(id_category, title, searchArticlePage)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticlesList)
);
