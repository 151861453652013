import React from "react";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
import "./index.scss";

/* Toaster Imports */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyNewCommand } from "../../actions/toasts.actions";

/* Components Import */
import Menu from "../../components/Menu/index";

/* Pages Import */
import Login from "../Login/index";
import CommandsNew from "../CommandsNew/index";
import CommandsNewDetails from "../CommandsNewDetails/index";
import CommandsPending from "../CommandsPending/index";
import CommandsPendingDetails from "../CommandsPendingDetails/index";
import CommandsCanceled from "../CommandsCanceled/index";
import CommandsCanceledDetails from "../CommandsCanceledDetails/index";
import CommandsHistory from "../CommandsHistory/index";
import CommandsHistoryDetails from "../CommandsHistoryDetails/index";
import Store from "../Store/index";
import User from "../User/index";

type MainProps = {
  // Redux State
  isLogged?: boolean;
  token?: string;
  id_store?: number;
  disconnected_id_store?: any;

  // Redux Actions
  toastifyNewCommand?: any;
};

const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
  cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
});

export const history = createHistory();

class Main extends React.Component<MainProps> {
  componentDidMount() {
    const {
      id_store,
      isLogged,
      disconnected_id_store,
      toastifyNewCommand,
    } = this.props;
    if (id_store !== null) {
      const channel = pusher.subscribe(`store-${id_store}`);
      channel.bind("new-command", (data) => {
        toastifyNewCommand(data);
      });
    }
    if (isLogged === false) {
      pusher.unsubscribe(`store-${disconnected_id_store}`);
    }
  }

  componentDidUpdate() {
    const {
      id_store,
      isLogged,
      toastifyNewCommand,
      disconnected_id_store,
    } = this.props;
    if (id_store !== null) {
      const channel = pusher.subscribe(`store-${id_store}`);
      channel.bind("new-command", (data) => {
        toastifyNewCommand(data);
      });
    }
    if (isLogged === false) {
      pusher.unsubscribe(`store-${disconnected_id_store}`);
    }
  }

  render() {
    const { isLogged } = this.props;

    return (
      <Router history={history}>
        {/* Load Menu and Toaster for all App */}
        <Menu />
        <ToastContainer
          hideProgressBar={true}
          autoClose={7000}
          closeOnClick={true}
        />
        {isLogged ? (
          <React.Fragment>
            <Switch>
              <Route exact path="/store" component={Store} />
              <Route exact path="/user" component={User} />
              <Route exact path="/commands_new" component={CommandsNew} />
              <Route
                exact
                path="/commands_new/details/:id"
                component={CommandsNewDetails}
              />
              <Route
                exact
                path="/commands_pending"
                component={CommandsPending}
              />
              <Route
                exact
                path="/commands_pending/details/:id"
                component={CommandsPendingDetails}
              />
              <Route
                exact
                path="/commands_canceled"
                component={CommandsCanceled}
              />
              <Route
                exact
                path="/commands_canceled/details/:id"
                component={CommandsCanceledDetails}
              />
              <Route
                exact
                path="/commands_history"
                component={CommandsHistory}
              />
              <Route
                exact
                path="/commands_history/details/:id"
                component={CommandsHistoryDetails}
              />

              <Redirect to="/store" />
            </Switch>
          </React.Fragment>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />
            <Redirect to="/" />
          </Switch>
        )}
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogged: state.user.isLogged,
  token: state.user.token,
  id_store: state.user.id_store,
  disconnected_id_store: state.user.disconnected_id_store,
});

const mapDispatchToProps = (dispatch) => ({
  toastifyNewCommand: (data) => dispatch(toastifyNewCommand(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
